import { motion } from "framer-motion";

const Accessibility = () => {
  /* UPDATE META DATA */
  const metaTitle = "Accessibility | Amberwood Real Estate";
  const metaDescription = "Our commitment to accessibility is at the forefront of our mission. Explore our 'Accessibility' page to learn about the steps we've taken to ensure our website is inclusive and accessible to all users."
  const metaImage = "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png"

  document.title = metaTitle;
  document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);

  document.querySelector('meta[property="og:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[property="og:image"]').setAttribute('content', metaImage);
  document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

  document.querySelector('meta[name="twitter:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[name="twitter:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[name="twitter:image"]').setAttribute('content', metaImage);
  return (
    <>
      <section className="external-pages">
        {/* BANNER */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="page-banner accessibility"
        >
          <div className="heading-overlay">
            <div className="container">
              <h1>ACCESSIBILITY</h1>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="content"
        >
          <div className="container">
            <p>
              We strive to provide a website in which all functionality and
              content are accessible to all individuals, and we are updating our
              site regularly to make it as accessible as possible. Our website
              strives to meet Level AA Success Criteria of the Web Content
              Accessibility Guidelines 2.0 to the greatest extent feasible.
            </p>
            <p className="mt-2">
              Should you require assistance in navigating our website or
              searching for real estate, please contact our offices at{" "}
              <a
                href="tel:+56227992388"
                style={{
                  textDecoration: "none",
                  color: "#CD5828",
                  fontWeight: "700",
                }}
              >
                (562) 799-2388
              </a>
              .
            </p>
          </div>
        </motion.div>
      </section>
    </>
  );
};

export default Accessibility;
