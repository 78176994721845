import React from "react";
import Skeleton from 'react-loading-skeleton';
import { Button, Row, Col} from "react-bootstrap";
import { motion } from "framer-motion";

/* EXTERNAL STYLES */
import 'react-loading-skeleton/dist/skeleton.css';

/* ICON IMPORTS */
import FacebookIcon from '@iconscout/react-unicons/icons/uil-facebook-f';
import InstagramIcon from "@iconscout/react-unicons/icons/uil-instagram";
import LinkedinIcon from "@iconscout/react-unicons/icons/uil-linkedin";
import TwitterIcon from "@iconscout/react-unicons/icons/uil-twitter";
import GlobeIcon from "@iconscout/react-unicons/icons/uil-globe";

import PhoneInput from "react-phone-number-input";

const AgentsLoading = () => {
    return (
      <>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="top-boxes"
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-sm-12">
                <div className="agent-profile-box">
                  <div className="row">
                    <div className="col-xl-4 col-sm-12">
                      <Skeleton className="agent-image" />
                    </div>
                    <div className="col-xl-8 col-sm-12">
                      <div className="agent-details">
                        <div className="details-header">
                          <h1>
                            <Skeleton width={"60%"} />
                          </h1>
                          <div className="d-flex align-items-center social-links">
                            <Button className="social-icon facebook">
                              <FacebookIcon color="#323232" size="22" />
                            </Button>
                            <Button className="social-icon instagram">
                              <InstagramIcon color="#323232" size="22" />
                            </Button>
                            <Button className="social-icon linkedin">
                              <LinkedinIcon color="#323232" size="22" />
                            </Button>
                            <Button className="social-icon twitter">
                              <TwitterIcon color="#323232" size="22" />
                            </Button>
                            <Button className="social-icon website">
                              <GlobeIcon color="#323232" size="22" />
                            </Button>
                          </div>
                        </div>
                        <div className="details-body">
                          <p>
                            <span>Agent License:</span>{" "}
                            <Skeleton width={"30%"} />
                          </p>
                          <p>
                            <span>Phone number:</span>{" "}
                            <Skeleton width={"30%"} />
                          </p>
                          <p>
                            <span>Email Address:</span>{" "}
                            <Skeleton width={"50%"} />
                          </p>
                          <div className="cta-btns">
                            <Button className="send-email">SEND EMAIL</Button>
                            <Button className="call-now">CALL NOW</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-12">
                <form className="inquiry-form">
                  <h5>Schedule a call with me</h5>
                  <div className="row">
                    <div className="col-xl-6 col-sm-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          name="name"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-12">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email Address"
                          name="email"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <PhoneInput
                      placeholder="Phone Number"
                      defaultCountry="US"
                      className="phone-number-select"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      rows={3}
                      className="textarea-control"
                      name="comments"
                      placeholder="Message"
                    />
                  </div>
                  <Button className="submit-btn">SEND MESSAGE</Button>
                </form>
              </div>
            </div>
          </div>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="about-agent"
        >
          <div className="container">
            <div className="bio-box">
              <div className="box-header">
                <h2>
                  <Skeleton width={"25%"} />
                </h2>
                <div className="underline" />
              </div>
              <div className="box-body">
                <p>
                  <Skeleton width={"100%"} height={"150px"} />
                </p>
              </div>
            </div>
          </div>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="last-transactions"
        >
          <div className="container">
            <h2>
              <Skeleton width={"35%"} />
            </h2>
            <div className="row mt-2  ">
              <div
                className="col-xl-3 col-sm-12 card listing-card m-2"
                style={{ width: "23.5%" }}
              >
                <div className="pt-3 pb-3 border-bottom">
                  <Skeleton height={"25px"} width={"40%"} />
                  <Skeleton style={{ marginTop: "20px" }} width={"70%"} />
                  <Row>
                    <Col size="6">
                      <Skeleton width={"80%"} />
                    </Col>
                    <Col size="6">
                      <Skeleton style={{ float: "right" }} width={"40%"} />
                    </Col>
                  </Row>
                </div>
                <div className="pt-3 pb-3 ">
                  <Skeleton height={"25px"} />
                </div>
              </div>
              <div
                className="col-xl-3 col-sm-12 card listing-card m-2"
                style={{ width: "23.5%" }}
              >
                <div className="pt-3 pb-3 border-bottom">
                  <Skeleton height={"25px"} width={"40%"} />
                  <Skeleton style={{ marginTop: "20px" }} width={"70%"} />
                  <Row>
                    <Col size="6">
                      <Skeleton width={"80%"} />
                    </Col>
                    <Col size="6">
                      <Skeleton style={{ float: "right" }} width={"40%"} />
                    </Col>
                  </Row>
                </div>
                <div className="pt-3 pb-3 ">
                  <Skeleton height={"25px"} />
                </div>
              </div>
              <div
                className="col-xl-3 col-sm-12 card listing-card m-2"
                style={{ width: "23.5%" }}
              >
                <div className="pt-3 pb-3 border-bottom">
                  <Skeleton height={"25px"} width={"40%"} />
                  <Skeleton style={{ marginTop: "20px" }} width={"70%"} />
                  <Row>
                    <Col size="6">
                      <Skeleton width={"80%"} />
                    </Col>
                    <Col size="6">
                      <Skeleton style={{ float: "right" }} width={"40%"} />
                    </Col>
                  </Row>
                </div>
                <div className="pt-3 pb-3 ">
                  <Skeleton height={"25px"} />
                </div>
              </div>
              <div
                className="col-xl-3 col-sm-12 card listing-card m-2"
                style={{ width: "23.5%" }}
              >
                <div className="pt-3 pb-3 border-bottom">
                  <Skeleton height={"25px"} width={"40%"} />
                  <Skeleton style={{ marginTop: "20px" }} width={"70%"} />
                  <Row>
                    <Col size="6">
                      <Skeleton width={"80%"} />
                    </Col>
                    <Col size="6">
                      <Skeleton style={{ float: "right" }} width={"40%"} />
                    </Col>
                  </Row>
                </div>
                <div className="pt-3 pb-3 ">
                  <Skeleton height={"25px"} />
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </>
    );
};

export default AgentsLoading;