/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, Modal } from 'react-bootstrap';
import Select from 'react-select';
import PhoneInput from "react-phone-number-input";
import { useFormik } from "formik";
import * as yup from "yup";
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { ReactSEOMetaTags } from "react-seo-meta-tags";

/* PACKAGE STYLES */
import "react-phone-number-input/style.css";

/* APIS */
import { getAgents, contactUs } from "../../service/api";

// LOADING SCREEN
import AgentsLoading from "../../components/loading-agent"

/* ICON IMPORTS */
import PhoneIcon from '@iconscout/react-unicons/icons/uil-phone';
import EstateIcon from '@iconscout/react-unicons/icons/uil-estate';

/* AGENT IMAGES */
import { IMAGE_URL } from "../../config";
import { Helmet } from "react-helmet";


const sortingOptions = [
  { value: 1, label: 'Name (A-Z)' },
  { value: -1, label: 'Name (Z-A)' }
];

const AgentCard = (props) => {
  const [contactModal, setContactModal] = useState(false);
  const [loading, setLoading] = useState(false);

  /* VALIDATION SCHEMA */
  const validationSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup.string().email().required("Email is required"),
    phone: yup.string().required("Phone Number is required"),
    message: yup.string().required("Message is required"),
  });

  /* FORM VALUES HANDLING */
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
      type: "CONTACTUS",
      agentId: props?.agentId
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const obj = {
        "name": values.firstName + ' ' + values?.lastName,
        "email": values.email,
        "phone": values.phone,
        "message": values.message,
        "type": values.type,
        "agentId": values.agentId,
      }
      setLoading(true)
      contactUs(obj)
        .then((res) => {
          if (res?.status) {
            console.log(res?.message);
            toast.success(res?.message);
            formik.resetForm();
            setContactModal(!contactModal)
          } else {
            console.log(res?.message);
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message);
        }).finally(res => {
          setLoading(false)
        });
    }
  });

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="agent-card">
        <img
          style={{ objectFit: "cover" }}
          src={props.agentImage}
          alt={props.firstName + props.lastName}
        />
        <div className="agent-details">
          <h2>
            {props.firstName} {props.lastName}
          </h2>
          <div className="d-flex align-items-center justify-content-center">
            <Link
              href={`tel:${props.telephone}`}
              aria-label="Call to action"
              className="d-flex align-items-center telephone"
            >
              <PhoneIcon size="18" color="#323232" />
              <p>{props.telephone}</p>
            </Link>
            <div className="d-flex align-items-center dre-number">
              <EstateIcon size="18" color="#323232" />
              <p>DRE #{props.realEstateNumber}</p>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center engagement-btns">
            <Link
              to={
                "/agent-detail/" +
                props?.agentId +
                "&name=" +
                props.firstName +
                " " +
                props.lastName
              }
              aria-label="View Agent Profile"
            >
              <Button className="view-profile-btn">View Profile</Button>
            </Link>
            <Button
              className="contact-btn"
              onClick={() => setContactModal(!contactModal)}
            >
              Contact Me
            </Button>
          </div>
        </div>
      </div>

      {/* CONTACT AGENT MODAL */}
      <Modal
        show={contactModal}
        size="lg"
        centered
        onHide={() => setContactModal(!contactModal)}
      >
        <Modal.Header className="contact-agent-header">
          <div>
            <h4>
              Ask our agent, {props.firstName} {props.lastName}
            </h4>
            <p>
              {props.firstName} {props.lastName} responds in about 1 minute
              during business hours
            </p>
          </div>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body className="contact-agent-modalbody">
            <div className="row">
              <div className="col-xl-6 col-sm-12">
                <div className="form-group">
                  <label className="form-label">
                    First Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.firstName && formik.touched.firstName && (
                    <small style={{ color: "red" }}>
                      {formik.errors.firstName}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-sm-12">
                <div className="form-group">
                  <label className="form-label">
                    Last Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.lastName && formik.touched.lastName && (
                    <small style={{ color: "red" }}>
                      {formik.errors.lastName}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-sm-12">
                <div className="form-group">
                  <label className="form-label">
                    Email Address <span>*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email Address"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    name="email"
                  />
                  {formik.errors.email && formik.touched.email && (
                    <small style={{ color: "red" }}>
                      {formik.errors.email}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-sm-12">
                <div className="form-group">
                  <label className="form-label">
                    Phone <span>*</span>
                  </label>
                  <PhoneInput
                    placeholder="Phone Number"
                    defaultCountry="US"
                    className="phone-number-select"
                    name="phone"
                    value={formik.values.phone}
                    onChange={(phone) => formik.setFieldValue("phone", phone)}
                  />
                  {formik.errors.phone && formik.touched.phone && (
                    <small style={{ color: "red" }}>
                      {formik.errors.phone}
                    </small>
                  )}
                </div>
              </div>
              <div className="col-xl-12 col-sm-12">
                <div className="form-group">
                  <label className="form-label">What can we do for you?</label>
                  <textarea
                    rows={4}
                    className="textarea-control"
                    name="comments"
                    placeholder="I'd like to know more about homes near me"
                    value={formik.values.message}
                    onChange={(message) =>
                      formik.setFieldValue("message", message.target.value)
                    }
                  />
                  {formik.errors.message && formik.touched.message && (
                    <small style={{ color: "red" }}>
                      {formik.errors.message}
                    </small>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="contact-agent-cardfooter">
            <Button
              className="cancel-btn"
              onClick={() => setContactModal(!contactModal)}
            >
              Cancel
            </Button>
            {loading ? (
              <Button className="submit-btn">Please Wait</Button>
            ) : (
              <Button className="submit-btn" type="submit">
                Submit
              </Button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

const OurAgents = () => {
  
  /* UPDATE META DATA */
  const metaTitle = "Our Agents | Amberwood Real Estate";
  const metaDescription = "Meet our exceptional team of experienced real estate agents dedicated to guiding you through your property journey."
  const metaImage = "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png"

  document.title = metaTitle;
  document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);

  document.querySelector('meta[property="og:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[property="og:image"]').setAttribute('content', metaImage);
  document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

  document.querySelector('meta[name="twitter:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[name="twitter:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[name="twitter:image"]').setAttribute('content', metaImage);

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [sortBy, setSortBy] = useState(1);
  const [agentsList, setAgentsList] = useState([])
  const [agentsListSorted, setAgentsListSorted] = useState([])
  const [totalAgents, setTotalAgents] = useState(null);

  const [search, setSearch] = useState({
    firstName: '',
    lastName: '',
    language: ''
  });

  useEffect(() => {
    getAgentsListFunction()
  }, []);

  useEffect(() => {
    getAgentsListFunction()
  }, [sortBy]);

  /* GET AGENT LISTING */
  const getAgentsListFunction = () => {
    setLoading(true);

    const obj = {};
    obj.page = page;
    obj.sizePerPage = pageSize;
    obj.sort = sortBy;
    if (search?.firstName) {
      obj.firstName = search?.firstName
    }
    if (search?.lastName) {
      obj.lastName = search?.lastName
    }
    if (search?.language) {
      obj.language = search?.language
    }

    getAgents(obj).then((res) => {
      if (res?.status) {
        setAgentsList(res?.data?.docs);
        setTotalAgents(res?.data?.totalDocs)
      }
      setLoading(false)
    })
      .catch(err => {
        console.log("err", err)
      })
      .finally(err => {
        setLoading(false)
      })

    return (() => {
      setAgentsList([])
    })
  }

  /* CLEAR FILTERS APPLIED */
  const clearSearchFunction = () => {
    search.language = ''
    search.firstName = ''
    search.lastName = ''
    document.getElementById('firstName').value = ''
    document.getElementById('lastName').value = ''
    getAgentsListFunction()
  }

  /* HANDLING FUNCTION */
  const handleChange = (event) => {
    let temp = search;
    temp[event.target.name] = event.target.value
    console.log(temp)
  };

  useEffect(() => {
    if (totalAgents > 0) {
      let data = agentsList.reduce((r, e) => {
        let alphabet = e.firstName[0].toUpperCase();
        if (!r[alphabet]) r[alphabet] = { alphabet, record: [e] }
        else r[alphabet].record.push(e);
        return r;
      }, {});
      let result = Object.values(data);
      setAgentsListSorted(result)
    }
  }, [agentsList])

  return (
    <>
      <section className="our-agents">
        {/* BANNER */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="page-banner"
        >
          <div className="heading-overlay">
            <div className="container">
              <h1>OUR AGENTS</h1>
            </div>
          </div>
        </motion.div>

        {/* AGENTS LIST */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="agents-list"
        >
          <div className="container">
            {/* SEARCH FILTERS */}
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              className="row align-items-end"
            >
              <div className="col-xl-4 col-sm-12">
                <div className="form-group">
                  <label className="form-label">First Name</label>
                  <input
                    type="text"
                    placeholder="First Name"
                    className="form-control"
                    name="firstName"
                    id="firstName"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-xl-4 col-sm-12">
                <div className="form-group">
                  <label className="form-label">Last Name</label>
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="form-control"
                    name="lastName"
                    id="lastName"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-xl-4 col-sm-12">
                <div className="d-flex align-items-center">
                  <Button
                    className="clear-btn"
                    onClick={async () => {
                      clearSearchFunction();
                    }}
                  >
                    CLEAR FILTER
                  </Button>
                  <Button
                    className="search-btn"
                    onClick={(e) => {
                      getAgentsListFunction();
                    }}
                  >
                    SEARCH AGENT
                  </Button>
                </div>
              </div>
            </motion.div>

            {/* MORE INFORMATION */}
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              className="more-info"
            >
              <p>
                Showing <span>{totalAgents ? totalAgents : 0} agents</span>
              </p>
              <Select
                options={sortingOptions}
                className="react-select"
                classNamePrefix="custom-select-two"
                placeholder="Sort by"
                aria-label="Sort By Alphabetical Order"
                onChange={(e) => {
                  setSortBy(e.value);
                }}
              />
            </motion.div>

            {/* AGENTS LIST */}
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              className="alphabetical-list"
            >
              <div className="row">
                <div className="col-xl-12 col-sm-12">
                  <Card className="alphabet-box">
                    {loading ? (
                      <AgentsLoading />
                    ) : agentsListSorted ? (
                      totalAgents > 0 ? (
                        agentsListSorted.map((data) => (
                          <>
                            <Card.Header className="alphabet-box-cardheader">
                              <h2>{data?.alphabet}</h2>
                            </Card.Header>

                            <Card.Body className="alphabet-box-cardbody">
                              <div className="row gy-3">
                                {data?.record.map((element) => {
                                  return (
                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                      <AgentCard
                                        firstName={element?.firstName}
                                        lastName={element?.lastName}
                                        agentImage={
                                          element?.image
                                            ? `${IMAGE_URL + element.image}`
                                            : "https://storage.googleapis.com/cdn-amberwoodre/images/agent-placeholder.png"
                                        }
                                        telephone={element?.mobile}
                                        realEstateNumber={element?.licence}
                                        agentId={element._id}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </Card.Body>
                          </>
                        ))
                      ) : null
                    ) : null}
                  </Card>
                </div>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </section>
    </>
  );
};

export default OurAgents;