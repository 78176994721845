const actions = {
  SET_UNPARSED_ADDRESS: "SET_UNPARSED_ADDRESS",
  SET_CITY: "SET_CITY",
  SET_STATE: "SET_STATE",
  SET_COUNTRY: "SET_COUNTRY",
  SET_ZIP_CODE: "SET_ZIP_CODE",
  SET_LAT: "SET_LAT",
  SET_LNG: "SET_LNG",
  SET_STREET_NAME: "SET_STREET_NAME",
  SET_STREET_NUMBER: "SET_STREET_NUMBER",
  SET_DETAILS: "SET_DETAILS",
  setUnparsedAddress: (data) => {
    return { type: actions.SET_UNPARSED_ADDRESS, data };
  },
  setCity: (data) => {
    return { type: actions.SET_CITY, data };
  },
  setState: (data) => {
    return { type: actions.SET_STATE, data };
  },
  setCountry: (data) => {
    return { type: actions.SET_COUNTRY, data };
  },
  setZipCode: (data) => {
    return { type: actions.SET_ZIP_CODE, data };
  },
  setLat: (data) => {
    return { type: actions.SET_LAT, data };
  },
  setLng: (data) => {
    return { type: actions.SET_LNG, data };
  },
  setStreetName: (data) => {
    return { type: actions.SET_STREET_NAME, data };
  },
  setStreetNumber: (data) => {
    return { type: actions.SET_STREET_NUMBER, data };
  },
  setDetails: (data) => {
    return { type: actions.SET_DETAILS, data };
  },
};


export default actions;
