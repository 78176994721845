import React from "react";

/* IMPORT TOASTER FOR NOTIFICATION */
import { ToastContainer } from "react-toastify";

/* HEADER AND FOOTER */
import Header from "./header/header-full-width";
import Footer from "./footer/footer-full-width";

const FullWidthLayout = ({ children }) => {
  return (
    <main>
      <Header />
      <ToastContainer />
      <div className="main">{children}</div>
      <Footer />
    </main>
  );
};

export default FullWidthLayout;
