const actions = {
  SET_USER: "SET_USER",
  SET_TOKEN: "SET_TOKEN",
  SET_LOGGEDIN: "SET_LOGGEDIN",
  SET_LOADING: "SET_LOADING",
  SET_MLS: "SET_MLS",
  SET_ITEM: "SET_ITEM",
  SET_AGENT_ID: "SET_AGENT_ID",
  SET_LAT_LNG: "SET_LAT_LNG",
  SET_BLOG_DETAILS: "SET_BLOG_DETAILS",
  SET_LEAD_DETAILS: "SET_LEAD_DETAILS",
  setUser: (data) => {
    return { type: actions.SET_USER, data };
  },
  setToken: (data) => {
    return { type: actions.SET_TOKEN, data };
  },
  setLoggedin: (data) => {
    return { type: actions.SET_LOGGEDIN, data };
  },
  setLoading: (data) => {
    return {
      type: actions.SET_LOADING,
      data,
    };
  },
  setMLS: (data) => {
    return {
      type: actions.SET_MLS,
      data,
    };
  },
  setItem: (data) => {
    return {
      type: actions.SET_ITEM,
      data,
    };
  },
  setAgentId: (data) => {
    return {
      type: actions.SET_AGENT_ID,
      data,
    };
  },
  setLatLng: (data) => {
    return {
      type: actions.SET_LAT_LNG,
      data,
    };
  },
  setBlogDetails: (data) => {
    return {
      type: actions.SET_BLOG_DETAILS,
      data,
    };
  },
  setLeadID: (data) => {
    return {
      type: actions.SET_LEAD_DETAILS,
      data,
    };
  },
};


export default actions;
