import { Button } from "react-bootstrap";
import { motion } from "framer-motion";
import { IMAGE_URL } from '../../config';
 
const BecomeAnAgent = () => {
  /* UPDATE META DATA */
  const metaTitle = "Become an Agent | Amberwood Real Estate";
  const metaDescription = "Embark on an exciting career in real estate by becoming an agent with our renowned agency. Our 'Become an Agent' page offers valuable resources, training programs, and mentorship opportunities to help you kickstart your journey."
  const metaImage = "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png"

  document.title = metaTitle;
  document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);

  document.querySelector('meta[property="og:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[property="og:image"]').setAttribute('content', metaImage);
  document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

  document.querySelector('meta[name="twitter:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[name="twitter:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[name="twitter:image"]').setAttribute('content', metaImage);
    return (
      <>
        <section className="become-an-agent">
          {/* BANNER */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            className="page-banner"
          >
            <div className="heading-overlay">
              <div className="container">
                <h1>BECOME AN AGENT</h1>
              </div>
            </div>
          </motion.div>

          {/* CONTENT */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            className="content"
          >
            <div className="container">
              <p>
                If you’re currently trying to decide what to do in terms of a
                career, it might be time to consider real estate. Real estate is
                a thriving industry and becoming a real estate agent can provide
                you with wonderful opportunities.
              </p>
              <h2>Is a Career in Real Estate Right For You?</h2>
              <p>
                People are always buying and selling property. As such, there’s
                always a need for real estate agents. However, there are a
                number of other reasons why being a real estate might be the
                perfect job for you. The following are a few of the benefits of
                being a real estate agent:
              </p>
              <ul className="content-list">
                <li>
                  <span>You don’t need to get a degree.</span> Although you will
                  need training so that you can pass the real estate licensing
                  exam in your state, this can take as little as a few weeks or
                  a few months depending on where you live. Compared to the four
                  years of college that many careers require, it’s much easier
                  to get started in the real estate industry.
                </li>
                <li>
                  <span>You can be your own boss.</span> As a real estate agent,
                  you’ll be an independent contractor. This gives you the
                  freedom to grow and become as successful as you want to be.
                </li>
                <li>
                  <span>You can earn a lot.</span> You won’t be limited by an
                  hourly wage or a set salary. Your income is based on how
                  successful you are. This means that the harder you work, the
                  more you are likely to earn.
                </li>
                <li>
                  <span>You’ll have a flexible schedule.</span> While you will
                  need to be flexible to the needs of your clients, you also
                  won’t be working set hours. Many real estate agents love the
                  work/life balance this career provides.
                </li>
                <li>
                  <span>You get to be social.</span> For some, this is a HUGE
                  plus! Real estate agents have to be social to be successful.
                  You’ll be talking to buyers, sellers, and other agents
                  regularly. If you’re a social person, this might be the
                  perfect job for you.
                </li>
              </ul>
              <h2>Difference Between a Listing Agent and a Buyer’s Agent</h2>
              <p className="mb-4">
                Before you dive any deeper into the possibility of becoming a
                real estate agent, it helps to understand what the position
                entails. There’s a lot more to being a real estate agent than
                showing homes. First of all, there are two main types of real
                estate agents: buyer’s agents and listing agents. The following
                is a brief breakdown of the responsibilities of each type:
              </p>
              <div className="row align-items-center">
                <div className="col-xl-4 col-sm-12">
                  <img
                    src={IMAGE_URL + "images/difference-bet-lister-buyer.webp"}
                    alt="Difference between Listing Agent and Buyer's Agent"
                  />
                </div>
                <div className="col-xl-8 col-sm-12">
                  <h3 className="mt-0">Buyer's Agent</h3>
                  <p>
                    A buyer’s agent represents the homebuyer. They work closely
                    with the buyer to determine what their needs and wants are,
                    what area they want to live in, and what their budget is.
                    Using this information, they will use their network of
                    resources (including listing services and other agents in
                    the area) to find properties that meet the criteria of the
                    buyers. They will help schedule showings, provide advice
                    concerning financing, identify good deals, negotiate prices
                    on the buyer’s behalf, and help put together a bid.
                    Additionally, they will guide the buyer through the closing
                    process.
                  </p>
                  <h3>Listing Agent</h3>
                  <p>
                    A listing agent is a real estate agent that represents the
                    seller. They are often known as seller’s agents. They help
                    to price the house accurately, offer advice to prep and
                    stage the house prior to the listing, list the property in
                    several listing services, schedule open houses as well as
                    private showings, use their network of agents to find
                    motivated buyers, negotiate with buyer’s agents, and help
                    guide the seller through the closing process.
                  </p>
                </div>
              </div>
              <h3 className="big-size">How to Become a Real Estate Agent</h3>
              <p>
                Becoming a real estate agent is different in every state;
                however, here in California, you’ll need to do the following:
              </p>
              <ul className="content-list">
                <li>You will have to be at least 18 years of age.</li>
                <li>
                  You will need to complete 135 hours of pre-licensing
                  education. This will include three 45-hour courses: real
                  estate principles, real estate finance, and real estate
                  practices.
                </li>
                <li>
                  You will need to find a real estate broker licensed in
                  California to work under.
                </li>
                <li>
                  You will need to apply for the state Salesperson Exam and then
                  submit an application for a salesperson license.
                </li>
                <li>
                  Once you’ve passed the Salesperson License Exam, you will be
                  eligible for licensing.
                </li>
              </ul>
              <p>
                Because it can take time to do all of this, you can expect to
                get your real estate license within about five to six months.
                You will also need to pay a handful of fees, including
                pre-licensing course tuition (which can range from $125 to
                $700), a $60 exam fee, and a $245 licensing fee.
              </p>
              <h3 className="big-size">Become An Agent</h3>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-sm-12">
                  <div className="bg-box">
                    <h3 className="grey-heading">Sales Agent Licensing</h3>
                    <p>
                      Enroll in the 3 courses required to qualify for the Sales
                      Agent State Exam.
                    </p>
                    <p>
                      Complete your courses and qualify for the State Exam in as
                      little as 2 months.
                    </p>
                    <a
                      href="https://dreamforceacademy.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="proceed-btn">
                        ENROLL IN SALES AGENT LICENSING
                      </Button>
                    </a>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-sm-12">
                  <div className="bg-box">
                    <h3 className="grey-heading">Sales Agent Prep Book</h3>
                    <p>
                      1000+ multiple choice questions prepare you for the Sales
                      Agent State Exam.
                    </p>
                    <p>
                      Questions highlight key concepts from the DRE’s State Exam
                      Outline.
                    </p>
                    <a
                      href="https://dreamforceacademy.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="proceed-btn">
                        ORDER THE SALES AGENT PREP BOOK
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </section>
      </>
    );
};

export default BecomeAnAgent;