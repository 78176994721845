/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import { InputNumberCommas } from "react-number-format-with-commas";
import * as yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { motion } from "framer-motion";

/* PACKAGE STYLES */
import "react-toastify/dist/ReactToastify.css";

/* API IMPORTS */
import { homeEvaluation } from "../../service/api";
import { Helmet } from "react-helmet";

const propertyTypes = [
  { value: "Single Family Residence", label: "Single Family Residence" },
  { value: "Town House", label: "Town House" },
  { value: "Mobile Home", label: "Mobile Home" },
  { value: "Condo", label: "Condo" },
  { value: "Apartment", label: "Apartment" },
  { value: "Rental", label: "Rental" },
  { value: "Multi-Family", label: "Multi-Family" },
  { value: "Res Land", label: "Res Land" },
  { value: "Land", label: "Land" },
  { value: "Acreage", label: "Acreage" },
  { value: "Commercial", label: "Commercial" },
  { value: "Non-Profit Organization", label: "Non-Profit Organization" },
  { value: "Duplex", label: "Duplex" },
  { value: "Triplex", label: "Triplex" },
  { value: "Fourplex", label: "Fourplex" },
];

const requestingMarketEvaluation = [
  {
    value: "SELLING",
    label: "Thinking of selling this home",
  },
  {
    value: "BUYING",
    label: "Thinking of buying this home",
  },
  { value: "NONE", label: "Just curious" },
];

const currentAgent = [
  {
    value: true,
    label: "Yes, I have an agent",
  },
  {
    value: false,
    label: "No, I don't have an agent",
  },
];

/* VALIDATION SCHEMA */
const validationSchema = yup.object().shape({
  streetAddress: yup.string().required("Address line 1 is required"),
  whyMarketEvaluation: yup.object().required("This field is required"),
  haveAgent: yup.object().required("This field is required"),
  email: yup.string().email().required("Email is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  zipCode: yup.string().required("Zip Code is required"),
  bedrooms: yup.string().required("Bedrooms are requried"),
  fullBathroom: yup.string().required("Full Bathrooms are required"),
});

const HomeEvaluation = () => {
  /* UPDATE META DATA */
  const metaTitle = "Home Evaluation | Amberwood Real Estate";
  const metaDescription = "Unlock the true value of your home with our comprehensive home evaluation service. Our expert team utilizes advanced market analysis tools to provide you with an accurate assessment of your property's worth."
  const metaImage = "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png"

  document.title = metaTitle;
  document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);

  document.querySelector('meta[property="og:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[property="og:image"]').setAttribute('content', metaImage);
  document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

  document.querySelector('meta[name="twitter:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[name="twitter:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[name="twitter:image"]').setAttribute('content', metaImage);

  const [loading, setLoading] = useState(false);

  /* FORM VALUUES HANDLING */
  const formik = useFormik({
    initialValues: {
      streetAddress: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      houseSize: "",
      lotSize: "",
      bedrooms: "",
      fullBathroom: "",
      partialBathroom: "",
      basement: "",
      garageSpace: "",
      estimatedValue: "",
      propertyType: { value: "", label: "" },
      propertyAge: "",
      propertyCondition: "",
      notes: "",
      whyMarketEvaluation: "",
      email: "",
      haveAgent: "",
    },
    // validationSchema
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      console.log(values);
      const formData = new FormData();
      formData.append("streetAddress", values?.streetAddress);
      formData.append(
        "whyMarketEvaluation",
        values?.whyMarketEvaluation?.value
      );
      formData.append("email", values?.email);
      formData.append("haveAgent", values?.haveAgent?.value);
      formData.append("city", values?.city);
      formData.append("state", values?.state);
      formData.append("zipCode", values?.zipCode);
      formData.append("bedrooms", values?.bedrooms);
      formData.append("fullBathroom", values?.fullBathroom);

      if (values?.addressLine2.trim().length !== 0) {
        formData.append("addressLine2", values?.addressLine2);
      }
      if(values?.propertyCondition){
        formData.append("propertyCondition", values?.propertyCondition);
      }
      if(values?.propertyAge){
        formData.append("propertyAge", values?.propertyAge);
      }
      if(values?.partialBathroom){
        formData.append("partialBathroom", values?.partialBathroom);
      }
      if (values?.houseSize.trim().length !== 0) {
        formData.append("houseSize", values?.houseSize);
      }
      if (values?.lotSize.trim().length !== 0) {
        formData.append("lotSize", values?.lotSize);
      }
      if (values?.basement.trim().length !== 0) {
        formData.append("basement", values?.basement);
      }
      if (values?.garageSpace.trim().length !== 0) {
        formData.append("garageSpace", values?.garageSpace);
      }
      if (values?.estimatedValue.trim().length !== 0) {
        formData.append("estimatedValue", values?.estimatedValue);
      }
      if (values?.propertyType?.value.trim().length !== 0) {
        formData.append("propertyType", values?.propertyType?.value);
      }
      if (values?.notes.trim().length !== 0) {
        formData.append("notes", values?.notes);
      }

      homeEvaluation(formData)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            resetForm();
          }
        })
        .catch((e) => {
          console.log("e-->", e);
          toast.error(e?.response?.data?.message);
          setLoading(false);
        })
        .finally((res) => {
          setLoading(false);
          // resetForm();
        });
    },
  });

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <>
      <section className="home-evaluation">
        {/* BANNER */}
        
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="page-banner"
        >
          <div className="heading-overlay">
            <div className="container">
              <h1>HOME EVALUATION</h1>
            </div>
          </div>
        </motion.div>

        {/* CONTENT */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="content"
        >
          <div className="container">
            <p>
              The first step in selling your home is acquiring a current
              evaluation of your home’s value. After all, the value of your
              current home will impact your budget for a new home, so it’s
              important to know where you are in terms of your home’s current
              market value. Once you know your home’s value, you can make
              important decisions like:
            </p>
            <ol className="content-list">
              <li>What kind of home can I afford?</li>
              <li>Where can I afford to look for a new home?</li>
              <li>What can I do to increase the value of my current home?</li>
              <li>
                Will it be worth it to fix up some things first, or sell as is?{" "}
              </li>
            </ol>
            <p>
              Selling your home is a big step, and it’s important you have a
              listing agent you can trust. Amberwood Real Estate Inc. is your
              one-stop-shop for all of your buying and selling needs. Your goals
              are important to us, let’s talk about them today!
            </p>
            {/* <p style={{ marginTop: '15px' }}>
            <a href="/home-evaluation-page" style={{ textDecoration: 'none', color: '#CD5828', fontWeight: '600' }}>Click here</a> to discover the true worth of your home
            with our expert evaluation, providing accurate insights for
            confident decision-making.
          </p> */}
          </div>
        </motion.div>

        {/* HOME EVALUATION FORM */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="home-evaluation-form"
        >
          <div className="container">
            <div className="form-box">
              <h2>Home Evaluation Form</h2>
              <p>
                To get you started on your path to buying and selling, simply
                complete the form below and we’ll send you an email with a
                custom assessment of your current home’s market value.
              </p>
              <form className="evaluation-form" onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Street Address <span>*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          formik.errors.streetAddress &&
                          formik.touched.streetAddress
                            ? "validation-border"
                            : ""
                        }`}
                        name="streetAddress"
                        value={formik.values.streetAddress}
                        onChange={formik.handleChange}
                        placeholder="Address Line 1"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control only-field"
                        name="addressLine2"
                        value={formik.values.addressLine2}
                        onChange={formik.handleChange}
                        placeholder="Address Line 2"
                      />
                      {formik.errors.addressLine2 &&
                        formik.touched.addressLine2 && (
                          <small style={{ color: "red" }}>
                            {formik.errors.addressLine2}
                          </small>
                        )}
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        City <span>*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          formik.errors.city && formik.touched.city
                            ? "validation-border"
                            : ""
                        }`}
                        name="city"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        placeholder="City"
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        State/Province/Region <span>*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          formik.errors.state && formik.touched.state
                            ? "validation-border"
                            : ""
                        }`}
                        name="state"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        placeholder="State/Province/Region"
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        ZIP/Postal Code <span>*</span>
                      </label>
                      <input
                        type="number"
                        className={`form-control ${
                          formik.errors.zipCode && formik.touched.zipCode
                            ? "validation-border"
                            : ""
                        }`}
                        name="zipCode"
                        value={formik.values.zipCode}
                        onChange={formik.handleChange}
                        placeholder="ZIP/Postal Code"
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        House Size (square feet)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="houseSize"
                        value={formik.values.houseSize}
                        onChange={formik.handleChange}
                        placeholder="House Size (square feet)"
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Lot/Site Size (acres)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="lotSize"
                        value={formik.values.lotSize}
                        onChange={formik.handleChange}
                        placeholder="Lot/Site Size (acres)"
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Basement</label>
                      <div className="d-flex align-items-center options-radio">
                        <Form.Check
                          type="radio"
                          className="status-radio"
                          label="None"
                          name="basement"
                          onChange={(e) => {
                            formik.setFieldValue("basement", "NONE");
                          }}
                          id="basement-none"
                        />
                        <Form.Check
                          type="radio"
                          className="status-radio"
                          label="Finished"
                          name="basement"
                          onChange={(e) => {
                            formik.setFieldValue("basement", "FINISHED");
                          }}
                          id="basement-finished"
                        />
                        <Form.Check
                          type="radio"
                          className="status-radio"
                          label="Unfinished"
                          name="basement"
                          value="UNFINISHED"
                          onChange={(e) => {
                            formik.setFieldValue("basement", e.target.value);
                          }}
                          id="basement-unfinished"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Bedrooms <span>*</span>
                      </label>
                      <input
                        type="number"
                        className={`form-control ${
                          formik.errors.bedrooms && formik.touched.bedrooms
                            ? "validation-border"
                            : ""
                        }`}
                        name="bedrooms"
                        value={formik.values.bedrooms}
                        onChange={formik.handleChange}
                        placeholder="No. of Bedrooms"
                        min="0"
                        onWheel={numberInputOnWheelPreventChange}
                      />
                      {formik?.values?.bedrooms < 0 && (
                        <small style={{ color: "red" }}>
                          Bedrooms number should be greater than zero
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Full Bathrooms <span>*</span>
                      </label>
                      <input
                        type="number"
                        className={`form-control ${
                          formik.errors.fullBathroom &&
                          formik.touched.fullBathroom
                            ? "validation-border"
                            : ""
                        }`}
                        name="fullBathroom"
                        value={formik.values.fullBathroom}
                        onChange={formik.handleChange}
                        placeholder="No. of Full Bathrooms"
                        min="0"
                        onWheel={numberInputOnWheelPreventChange}
                      />
                      {formik?.values?.fullBathroom < 0 && (
                        <small style={{ color: "red" }}>
                          Full Bathrooms number should be greater than zero
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Partial Bathrooms</label>
                      <input
                        type="number"
                        className="form-control"
                        name="partialBathroom"
                        value={formik.values.partialBathroom}
                        onChange={formik.handleChange}
                        placeholder="No. of Partial Bathrooms"
                        min="0"
                        onWheel={numberInputOnWheelPreventChange}
                      />
                      {formik?.values?.partialBathroom < 0 && (
                        <small style={{ color: "red" }}>
                          Partial Bathrooms number should be greater than zero
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Estimated Value</label>
                      <div className="p-relative">
                        <InputNumberCommas
                          className="form-control calculator-input"
                          name="estimatedValue"
                          id="purchasePrice"
                          value={formik.values.estimatedValue}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "estimatedValue",
                              e.target.value
                            );
                          }}
                          placeholder="120,000"
                        />
                        <p className="start-dollar">$</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Garage Spaces</label>
                      <input
                        type="text"
                        className="form-control"
                        name="garageSpace"
                        value={formik.values.garageSpace}
                        onChange={formik.handleChange}
                        placeholder="Garage Spaces"
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-12">
                    <div className="form-group" htmlFor="property-type">
                      <label className="form-label">Type of Property</label>
                      <Select
                        options={propertyTypes}
                        className="react-select"
                        classNamePrefix="custom-select"
                        placeholder="Property Type"
                        aria-label="Type of Property"
                        id="property-type"
                        value={
                          formik.values.propertyType.value !== ""
                            ? formik.values.propertyType
                            : null
                        }
                        onChange={(e) => {
                          formik.setFieldValue("propertyType", e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Age of Property</label>
                      <input
                        type="number"
                        className="form-control"
                        name="propertyAge"
                        value={formik.values.propertyAge}
                        onChange={formik.handleChange}
                        placeholder="Age of Property"
                        min="0"
                        onWheel={numberInputOnWheelPreventChange}
                      />
                      {formik?.values?.propertyAge < 0 && (
                        <small style={{ color: "red" }}>
                          Property age number should be greater than zero
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Condition of Property{" "}
                        <em>(1 - Poor, 10 - Excellent)</em>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="propertyCondition"
                        value={formik.values.propertyCondition}
                        onChange={formik.handleChange}
                        placeholder="Please enter a number from 1 to 10"
                        min="1"
                        max="10"
                        onWheel={numberInputOnWheelPreventChange}
                      />
                      {formik?.values?.propertyCondition < 1 &&
                        formik?.values?.propertyCondition !== "" && (
                          <small style={{ color: "red" }}>
                            Property Condition should be greater than one.
                          </small>
                        )}
                      {formik?.values?.propertyCondition > 10 &&
                        formik?.values?.propertyCondition !== "" && (
                          <small style={{ color: "red" }}>
                            Property Condition should be less than ten
                          </small>
                        )}
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-sm-12">
                    <div className="form-group">
                      <label
                        className="form-label"
                        htmlFor="requesting-market-evaluation"
                      >
                        Why are you requesting a Market Evaluation{" "}
                        <span>*</span>
                      </label>
                      <Select
                        options={requestingMarketEvaluation}
                        className={`react-select ${
                          formik.errors.whyMarketEvaluation &&
                          formik.touched.whyMarketEvaluation
                            ? "validation-border"
                            : ""
                        }`}
                        id="requesting-market-evaluation"
                        classNamePrefix="custom-select"
                        aria-label="Why are you requesting marker evaluation"
                        placeholder="Why are you requesting marker evaluation"
                        value={formik.values.whyMarketEvaluation}
                        onChange={(e) => {
                          formik.setFieldValue("whyMarketEvaluation", e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="currently-working">
                        Are you currently working with a Real Estate Agent?{" "}
                        <span>*</span>
                      </label>
                      <Select
                        options={currentAgent}
                        className={`react-select ${
                          formik.errors.haveAgent && formik.touched.haveAgent
                            ? "validation-border"
                            : ""
                        }`}
                        id="currently-working"
                        classNamePrefix="custom-select"
                        aria-label="Are you currently working with a Real Estate Agent"
                        placeholder="Are you currently working with a Real Estate Agent"
                        value={formik.values.haveAgent}
                        onChange={(e) => {
                          formik.setFieldValue("haveAgent", e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Email <span>*</span>
                      </label>
                      <input
                        type="email"
                        className={`form-control ${
                          formik.errors.email && formik.touched.email
                            ? "validation-border"
                            : ""
                        }`}
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        placeholder="Email Address"
                      />
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Notes</label>
                      <textarea
                        rows={4}
                        className="textarea-control"
                        name="notes"
                        value={formik.values.notes}
                        onChange={(notes) => {
                          formik.setFieldValue("notes", notes.target.value);
                        }}
                        placeholder="Additional notes"
                      />
                    </div>
                  </div>
                  <div className="col-xl-12 col-sm-12">
                    <Button className="submit-btn" type="submit">
                      {loading ? <Spinner size="sm" /> : "SUBMIT"}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </motion.div>
      </section>
    </>
  );
};

export default HomeEvaluation;
