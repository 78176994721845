import { motion } from "framer-motion";

const RealEstateFinanceCourse = () => {
  /* UPDATE META DATA */
  const metaTitle = "Real Estate Finance Course | Amberwood Real Estate";
  const metaDescription = "Take your real estate knowledge to the next level with our comprehensive real estate finance course. Our 'Real Estate Finance Course' page offers a structured curriculum designed to equip you with the essential skills and expertise needed for success in the industry."
  const metaImage = "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png"

  document.title = metaTitle;
  document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);

  document.querySelector('meta[property="og:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[property="og:image"]').setAttribute('content', metaImage);
  document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

  document.querySelector('meta[name="twitter:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[name="twitter:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[name="twitter:image"]').setAttribute('content', metaImage);
  return (
    <>
      <section className="re-course">
        {/* BANNER */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="page-banner"
        >
          <div className="heading-overlay">
            <div className="container">
              <h1>REAL ESTATE FINANCE COURSE</h1>
            </div>
          </div>
        </motion.div>

        {/* CONTENT */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="content"
        >
          <div className="container">
            <h2>What is Real Estate Finance Course?</h2>
            <p>
              Real Estate Finance is an elective course by the State of
              California, required by the California Department of Real Estate
              (DRE) to obtain a real estate license or brokers license, an
              introductory course with primary focus on the Real Estate Finance
              and policies of the Department of Real Estate.
            </p>
            <p>
              Real Estate Finance stablishes realistic financial objectives from
              appraisals to applying for and getting pre-approved for loans.
              This course provides relevant information for State Licensing
              Requirements for Mortgage Loan Originators. In addition, Federal
              regulations increasing the accuracy in calculating the cost or
              credit for the protection of the consumer.
            </p>
            <h2 className="mt-3">
              What are the requirements for California Real Estate Classes?
            </h2>
            <div className="row justify-content-center">
              <div className="col-xl-4 col-lg-6 col-sm-12">
                <div className="requirement-box">
                  <p>
                    Anyone whose age is over 18 who wishes to become a real
                    estate agent in California must complete a 40-hour real
                    estate certification program under the guidance of a
                    competent and registered trainer.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-sm-12">
                <div className="requirement-box">
                  <p>
                    In order to repeat the final exam in the state of
                    california, if a student doesn't receive a passing score of
                    60%, they must study the course content for a minimum of 18
                    days and should reappear for the examination.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-sm-12">
                <div className="requirement-box">
                  <p>
                    ln accordance with the California Department of Real
                    Estate's rules. The candidate must learn, comprehend, and
                    research the material for a minimum of 18 days before
                    completing the final exams.
                  </p>
                </div>
              </div>
            </div>
            <p className="mb-0 mt-3">
              For more information about Real Estate Finance Course, visit{" "}
              <a
                href="https://dreamforceacademy.org/real-estate-finance"
                target="_blank"
                rel="noreferrer"
                aria-labelledby="Dreamforce Academy"
              >
                Real Estate Finance Course
              </a>{" "}
              at Dreamforce Academy.
            </p>
          </div>
        </motion.div>
      </section>
    </>
  );
};

export default RealEstateFinanceCourse;
