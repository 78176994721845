/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import BlogSidebar from '../../components/blog-sidebar';
import { useParams } from "react-router-dom"
import { Toaster } from 'react-hot-toast';
import ReactHtmlParser from 'react-html-parser';
import { motion } from "framer-motion";

// IMPORT APIs
import { getBlogDetailsById } from "../../service/api";

const BlogDetail = () => {

  const { id } = useParams()
  const getId = id?.split('&')
  const [loading, setLoading] = useState(true);
  const [formSubmit, setFormSubmit] = useState(false);
  const [blogDetails, setBlogDetails] = useState(null)

  useEffect(() => {
    getBlogDetails();
  }, [getId[0]]);

  // Get Blog Details API
  const getBlogDetails = () => {
    setLoading(true);
    getBlogDetailsById(getId[0])
      .then((res) => {
        if (res?.status) {
          setBlogDetails(res?.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      })
      .finally((err) => {
        setLoading(false);
      });

    return (() => {
      setBlogDetails(null)
    })
  }

  useEffect(() => {
    if (blogDetails?.name !== undefined) {
      /* UPDATE META DATA */
      const metaTitle = blogDetails?.name + " | Amberwood Real Estate";
      const metaDescription = "Delve into a wealth of knowledge and inspiration through our engaging real estate blogs. Our 'Blogs' page is a treasure trove of informative articles, industry insights, and expert advice."
      const metaImage = "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png"

      document.title = metaTitle;
      document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);

      document.querySelector('meta[property="og:title"]').setAttribute('content', metaTitle);
      document.querySelector('meta[property="og:description"]').setAttribute('content', metaDescription);
      document.querySelector('meta[property="og:image"]').setAttribute('content', metaImage);
      document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

      document.querySelector('meta[name="twitter:title"]').setAttribute('content', metaTitle);
      document.querySelector('meta[name="twitter:description"]').setAttribute('content', metaDescription);
      document.querySelector('meta[name="twitter:image"]').setAttribute('content', metaImage);
    }
  }, [blogDetails])
  return (
    <section className="blog-detail">
      <Toaster position="top-right" reverseOrder={false} />
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="container"
      >
        {loading ? (
          ""
        ) : (
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-sm-12">
              <div className="blog-detail-desc">
                <h1>{blogDetails?.name}</h1>
                <div className="description-inner">
                  <p>{ReactHtmlParser(blogDetails?.discription)}</p>
                </div>
              </div>
            </div>

            {/* SIDEBAR */}
            <BlogSidebar />
          </div>
        )}
      </motion.div>
    </section>
  );
};

export default BlogDetail;