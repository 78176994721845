import { motion } from "framer-motion";

const PrivacyPolicy = () => {
  /* UPDATE META DATA */
  const metaTitle = "Privacy Policy | Amberwood Real Estate";
  const metaDescription = "Our commitment to your privacy is paramount. Explore our 'Privacy Policy' page to understand how we protect and handle your personal information."
  const metaImage = "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png"

  document.title = metaTitle;
  document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);

  document.querySelector('meta[property="og:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[property="og:image"]').setAttribute('content', metaImage);
  document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

  document.querySelector('meta[name="twitter:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[name="twitter:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[name="twitter:image"]').setAttribute('content', metaImage);
    return (
      <>
        <section className="external-pages">
          {/* BANNER */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            className="page-banner privacy-policy"
          >
            <div className="heading-overlay">
              <div className="container">
                <h1>PRIVACY POLICY</h1>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            className="content"
          >
            <div className="container">
              <p>
                Your privacy is important to Amberwood Real Estate Inc. and its
                client. This Privacy Policy outlines the information that this
                website may collect and how that information may be used.
              </p>
              <h5 className="mt-3">Information Collected and Used</h5>
              <p>
                This website does not collect personally identifiable
                information about visitors (e.g., name, address, telephone
                number, MLS information, credit card information or email
                address) unless you have submitted it. If you do not want your
                personal data collected, please do not submit it. Information
                you submit may be used to offer or provide products or services
                to you, communicate with you, collect fees and for other
                business purposes. Your personal information may be shared with
                or used by Amberwood Real Estate, its client, service providers,
                vendors, financial institutions and government officials or
                others if required by law or litigation. Your information will
                be retained for as long as necessary to permit it to be used for
                the purposes described above and to comply with applicable law
                or regulations.
              </p>
              <p>
                This website may automatically (i.e., not via registration)
                collect technical but not personally identifiable
                characteristics of computers and browsers visiting the site, and
                this information may be shared with others. Examples of this
                type of information include the Internet browser version and
                settings, the type of computer operating system and the
                referring domain name.
              </p>
              <h5 className="mt-3">Contacting You</h5>
              <p>
                Information you submit on the website and through other sources
                may be used to tell you about products and services that might
                interest you or to invite you to participate in surveys,
                seminars and other events. E-mail messages will provide a method
                by which you may opt-out of future mailings.
              </p>
              <p>
                We may need to communicate with you regarding the usability of
                products or services or critical issues relating to them, such
                as subscription renewal notices, critical notices or legally
                mandated notices. Providing us with your phone number is
                optional. By submitting your telephone number, you are
                consenting to being contacted via telephone, even if your name
                appears on the Federal “Do-not-call List”.
              </p>
              <h5 className="mt-3">Information and data security</h5>
              <p>
                Physical, electronic and managerial security procedures have
                been put in place to safeguard and secure the information
                collected by this website. However, Amberwood Real Estate Inc.
                cannot guarantee that these security procedures will not be
                breached and PropertyMinder will not be liable if information
                that belongs to you is obtained or used by an unintended person.
              </p>
              <h5 className="mt-3">Links</h5>
              <p>
                This website may contain links to other websites. We are not
                responsible for the privacy practices or the content of
                third-party websites.
              </p>
              <h5 className="mt-3">Policy Effective Date</h5>
              <p>
                The privacy policy is effective as of August 20, 2007. This
                policy may be amended at any time by posting a revised version
                on the website. The revised version will be effective at the
                time it is posted.
              </p>
            </div>
          </motion.div>
        </section>
      </>
    );
};

export default PrivacyPolicy;