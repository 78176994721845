import { motion } from "framer-motion";

const RealEstatePracticeCourse = () => {
  /* UPDATE META DATA */
  const metaTitle = "Real Estate Practice Course | Amberwood Real Estate";
  const metaDescription = "Take your real estate knowledge to the next level with our comprehensive real estate practice course. Our 'Real Estate Practice Course' page offers a structured curriculum designed to equip you with the essential skills and expertise needed for success in the industry."
  const metaImage = "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png"

  document.title = metaTitle;
  document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);

  document.querySelector('meta[property="og:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[property="og:image"]').setAttribute('content', metaImage);
  document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

  document.querySelector('meta[name="twitter:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[name="twitter:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[name="twitter:image"]').setAttribute('content', metaImage);
    return (
      <>
        <section className="re-course">
          {/* BANNER */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            className="page-banner"
          >
            <div className="heading-overlay">
              <div className="container">
                <h1>REAL ESTATE PRACTICE COURSE</h1>
              </div>
            </div>
          </motion.div>

          {/* CONTENT */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            className="content"
          >
            <div className="container">
              <h2>What is Real Estate Practice Course?</h2>
              <p>
                Real Estate Practice is required by the California Department of
                Real Estate (DRE) to obtain a real estate license, an
                introductory course with primary focus on the Real Estate
                Practice and policies of the Department of Real Estate.
              </p>
              <p>
                This course title is an excellent resource for improving your
                real estate knowledge and an excellent study guide for those
                taking the state salesperson and broker license exam, it covers
                the elements of day-to-day real estate sales and brokerage
                practices, also encompasses your education experience as well as
                professional career, emphasizing the selling process and the
                handling of a real estate transaction from listing to closing
                escrow and adding elements of trending real estate technology
                advances.
              </p>
              <h2 className="mt-3">
                What are the requirements for California Real Estate Classes?
              </h2>
              <div className="row justify-content-center">
                <div className="col-xl-4 col-lg-6 col-sm-12">
                  <div className="requirement-box">
                    <p>
                      Anyone whose age is over 18 who wishes to become a real
                      estate agent in California must complete a 40-hour real
                      estate certification program under the guidance of a
                      competent and registered trainer.
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-sm-12">
                  <div className="requirement-box">
                    <p>
                      In order to repeat the final exam in the state of
                      california, if a student doesn't receive a passing score
                      of 60%, they must study the course content for a minimum
                      of 18 days and should reappear for the examination.
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-sm-12">
                  <div className="requirement-box">
                    <p>
                      ln accordance with the California Department of Real
                      Estate's rules. The candidate must learn, comprehend, and
                      research the material for a minimum of 18 days before
                      completing the final exams.
                    </p>
                  </div>
                </div>
              </div>
              <p className="mb-0 mt-3">
                For more information about Real Estate Practice Course, visit{" "}
                <a
                  href="https://dreamforceacademy.org/real-estate-practice"
                  target="_blank"
                  rel="noreferrer"
                  aria-labelledby="Dreamforce Academy"
                >
                  Real Estate Practice Course
                </a>{" "}
                at Dreamforce Academy.
              </p>
            </div>
          </motion.div>
        </section>
      </>
    );
};

export default RealEstatePracticeCourse;