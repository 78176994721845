/* eslint-disable no-useless-concat */
import React from "react";
import { Card } from "react-bootstrap";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";

/* IMAGE IMPORTS */
import AmberwoodPlaceholder from "../assets/amberwood-placeholder-img.webp";

/* ICON IMPORTS */
import BedIcon from "../assets/icons/bed-icon.png";
import BathIcon from "../assets/icons/bath-icon.png";
import CameraIcon from "@iconscout/react-unicons/icons/uil-camera";


const PropertyLoading = (props) => {
  return (
    <motion.div className="card listing-card" layoutId={props.streetName}>
      <Card.Body className="listing-cardbody">
        <div className="property-images">
          <img
            src={AmberwoodPlaceholder}
            className="property-img"
            alt={props.streetName}
          />
          <div className="favorites">
            <div className="images-number">
              <CameraIcon size="16" color="#FFF" />
              <Skeleton className="loading-skeleton" />
            </div>
          </div>
        </div>
        <div className="property-details">
          <div className="top-section">
            <Skeleton className="loading-skeleton" />
            <Skeleton className="badge-loading-skeleton" />
          </div>
          <div className="middle-section">
            <Skeleton className="loading-skeleton" />
            <div className="d-flex align-items-center justify-content-between">
              <Skeleton className="city-loading-skeleton" />
            </div>
          </div>
        </div>
      </Card.Body>
      <Card.Footer className="listing-cardfooter">
        <div>
          <Skeleton className="area-loading-skeleton" />
          {/* <Skeleton className="lot-loading-skeleton" /> */}
        </div>
        <div className="other-details">
          <div className="details-box">
            <img src={BedIcon} className="icon" alt="Bed Icon" />
            <Skeleton className="bed-loading-skeleton" />
          </div>
          <div className="details-box">
            <img src={BathIcon} className="icon" alt="Bed Icon" />
            <Skeleton className="bed-loading-skeleton" />
          </div>
        </div>
      </Card.Footer>
    </motion.div>
  );
};

export default PropertyLoading;
