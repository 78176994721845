import React, { useState } from "react";
import Skeleton from 'react-loading-skeleton';
import { Button, Row, Card, Modal } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import 'react-loading-skeleton/dist/skeleton.css';

/* AGENT IMAGES */
import Agent02 from '../assets/agent-placeholder.png';
import Agent03 from '../assets/agent-placeholder.png';

/* ICON IMPORTS */
import PhoneIcon from '@iconscout/react-unicons/icons/uil-phone';
import EstateIcon from '@iconscout/react-unicons/icons/uil-estate';


const AgentCard = () => {
    const [contactModal, setContactModal] = useState(false);

    return (
      <>
        <div className="agent-card">
          <Skeleton style={{ paddingTop: "10px" }} height={"250px"} />
          <div className="agent-details">
            <h5>
              <Skeleton height={"25px"} />
            </h5>

            <p className="pt-2">
              <PhoneIcon size="18" color="#323232" />
              &nbsp;&nbsp;
              <Skeleton width={"60%"} />
            </p>
            <p>
              <EstateIcon size="18" color="#323232" />
              &nbsp;&nbsp;
              <Skeleton width={"60%"} />
            </p>

            <div className="d-flex align-items-center engagement-btns">
              <Button className="contact-btn">View Profile</Button>
              <Button
                className="contact-btn"
                onClick={() => setContactModal(!contactModal)}
              >
                Contact Me
              </Button>
            </div>
          </div>
        </div>

        {/* CONTACT AGENT MODAL */}
        <Modal
          show={contactModal}
          size="lg"
          centered
          onHide={() => setContactModal(!contactModal)}
        >
          <Modal.Header className="contact-agent-header">
            <div>
              <h4>Ask our agent, Ursulich</h4>
              <p>Ursulich responds in about 1 minute during business hours</p>
            </div>
          </Modal.Header>
          <Modal.Body className="contact-agent-modalbody">
            <form>
              <div className="row">
                <div className="col-xl-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      First Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      name="fName"
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Last Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      name="lName"
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Email Address <span>*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email Address"
                      name="emailAddress"
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Phone <span>*</span>
                    </label>
                    <PhoneInput
                      placeholder="Phone Number"
                      defaultCountry="US"
                      className="phone-number-select"
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      What can we do for you?
                    </label>
                    <textarea
                      rows={4}
                      className="textarea-control"
                      name="comments"
                      placeholder="I'd like to know more about homes near me"
                    />
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer className="contact-agent-cardfooter">
            <Button
              className="cancel-btn"
              onClick={() => setContactModal(!contactModal)}
            >
              Cancel
            </Button>
            <Button
              className="submit-btn"
              onClick={() => setContactModal(!contactModal)}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
};

const AgentsLoading = () => {
    return (
      <Row className="p-0">
        <Card className="alphabet-box" style={{ border: "none" }}>
          <Card.Header className="alphabet-box-cardheader">
            <h5>&nbsp; </h5>
          </Card.Header>
          <Card.Body className="alphabet-box-cardbody">
            <div className="row gy-5">
              <div className="col-xl-3 col-sm-12">
                <AgentCard
                  firstName="Andrew"
                  lastName="Binuya Jr."
                  agentImage={Agent02}
                  telephone="562-413-5704"
                  realEstateNumber="DRE #1722892"
                />
              </div>
              <div className="col-xl-3 col-sm-12">
                <AgentCard
                  firstName="Alicia"
                  lastName="Nguyen"
                  agentImage={Agent03}
                  telephone="562-673-8616"
                  realEstateNumber="DRE #1710824"
                />
              </div>
              <div className="col-xl-3 col-sm-12">
                <AgentCard
                  firstName="Alicia"
                  lastName="Nguyen"
                  agentImage={Agent03}
                  telephone="562-673-8616"
                  realEstateNumber="DRE #1710824"
                />
              </div>
              <div className="col-xl-3 col-sm-12">
                <AgentCard
                  firstName="Alicia"
                  lastName="Nguyen"
                  agentImage={Agent03}
                  telephone="562-673-8616"
                  realEstateNumber="DRE #1710824"
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      </Row>
    );
};

export default AgentsLoading;