import { motion } from "framer-motion";

const AboutUs = () => {
  /* UPDATE META DATA */
  const metaTitle = "About Us | Amberwood Real Estate";
  const metaDescription = "When buying or selling a new home, it’s important that you find an experienced and local real estate agent that you can trust. Here at Amberwood, that’s exactly what you’ll find."
  const metaImage = "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png"

  document.title = metaTitle;
  document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);

  document.querySelector('meta[property="og:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[property="og:image"]').setAttribute('content', metaImage);
  document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

  document.querySelector('meta[name="twitter:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[name="twitter:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[name="twitter:image"]').setAttribute('content', metaImage);
  return (
    <>
      <section className="about-us">
        {/* BANNER */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="page-banner"
        >
          <div className="heading-overlay">
            <div className="container">
              <h1>ABOUT AMBERWOOD REAL ESTATE INC.</h1>
            </div>
          </div>
        </motion.div>

        {/* CONTENT */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="content"
        >
          <div className="container">
            <p>
              When buying or selling a new home, it’s important that you find
              an experienced and local real estate agent that you can trust.
              Here at Amberwood Real Estate Inc., that’s exactly what you’ll
              find.
            </p>
            <div className="row mt-3">
              <div className="col-xl-6 col-sm-12">
                <div className="bg-box">
                  <h2>OUR MISSION</h2>
                  <p>
                    We have built a reputation as one of the best real estate
                    agencies in the area due to our commitment to providing
                    the best quality service in real estate to our community.
                    It is our goal to make your buying and selling experience
                    as convenient and painless as possible even in the most
                    challenging markets. We will go the extra mile to make
                    sure your needs are met while maintaining the highest
                    level of professionalism and integrity. When it comes to
                    real estate, your goals become our goals.
                  </p>
                  <img
                    src={require("../../assets/right-quote.png")}
                    className="right-quote"
                    alt="Left Quote"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-sm-12">
                <div className="bg-box">
                  <h2>OUR VALUES</h2>
                  <p>
                    It’s not just about buying and selling real estate. At
                    Amberwood Real Estate Inc., it’s about building
                    long-lasting relationships within the community and
                    helping our clients achieve their real estate dreams.
                    Integrity is important, which is why we work closely with
                    our clients and maintain open lines of communication and
                    constant transparency in everything we do. When you work
                    with our team, you will realize that we strive for
                    excellence in the details, no matter how inconsequential
                    they may seem to be.
                  </p>
                  <img
                    src={require("../../assets/right-quote.png")}
                    className="right-quote"
                    alt="Left Quote"
                  />
                </div>
              </div>
            </div>
            <h2>OUR GOALS</h2>
            <p>
              We will continue to grow our real estate agency by focusing on
              the following goals:
            </p>
            <ul className="content-list">
              <li>
                We will share our vision, inspire one another, and continue
                cultivating alliances within the industry.
              </li>
              <li>
                We will strive to exceed our expectations as well as the
                expectations of our clients.
              </li>
              <li>
                We will excel in communication between our team, other agents,
                and our clients.
              </li>
              <li>
                We will continue to build on the foundation that we have
                established by learning from our successes.
              </li>
              <li>
                We will find unmet needs and create effective solutions.
              </li>
              <li>
                We will have a strategic plan to help you succeed whether
                you’re buying, selling, or both.
              </li>
              <li>
                We will continue to set the standard within our industry for
                the quality of service you deserve.
              </li>
              <li>
                We will build our success on the relationships and trust that
                we foster.
              </li>
            </ul>
            <p>
              Whether you’re buying a home or interested in selling yours, we
              have the agents to help you reach your real estate goals. Real
              estate doesn’t have to be stressful, and we can help you every
              step of the way. Let’s talk about your plans for buying or
              selling today!
            </p>
          </div>
        </motion.div>
      </section>
    </>
  );
};

export default AboutUs;