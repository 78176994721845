import { motion } from "framer-motion";

const TwoDayCrashCourse = () => {
  /* UPDATE META DATA */
  const metaTitle = "Two Day Crash Course | Amberwood Real Estate";
  const metaDescription =
    "Take your real estate knowledge to the next level with our comprehensive two day crash course. Our 'Two Day Crash Course' page offers a structured curriculum designed to equip you with the essential skills and expertise needed for success in the industry.";
  const metaImage =
    "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png";

  document.title = metaTitle;
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", metaDescription);

  document
    .querySelector('meta[property="og:title"]')
    .setAttribute("content", metaTitle);
  document
    .querySelector('meta[property="og:description"]')
    .setAttribute("content", metaDescription);
  document
    .querySelector('meta[property="og:image"]')
    .setAttribute("content", metaImage);
  document
    .querySelector('meta[property="og:url"]')
    .setAttribute("content", window.location.href);

  document
    .querySelector('meta[name="twitter:title"]')
    .setAttribute("content", metaTitle);
  document
    .querySelector('meta[name="twitter:description"]')
    .setAttribute("content", metaDescription);
  document
    .querySelector('meta[name="twitter:image"]')
    .setAttribute("content", metaImage);
  return (
    <>
      <section className="re-course">
        {/* BANNER */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="page-banner"
        >
          <div className="heading-overlay">
            <div className="container">
              <h1>TWO-DAY CRASH COURSE</h1>
            </div>
          </div>
        </motion.div>

        {/* CONTENT */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="content"
        >
          <div className="container">
            <h2>What is Two-Day Crash Course?</h2>
            <p>
              This live Crash Course is a live 6 Hours Course in our Cerritos
              office. It is a last minute study session that will tie everything
              together and greatly improve your chances of the ultimate goal, to
              pass the state exam.
            </p>
            <h2 className="mt-3">
              What are the requirements for California Real Estate Classes?
            </h2>
            <div className="row justify-content-center">
              <div className="col-xl-4 col-lg-6 col-sm-12">
                <div className="requirement-box">
                  <p>
                    Anyone whose age is over 18 who wishes to become a real
                    estate agent in California must complete a 40-hour real
                    estate certification program under the guidance of a
                    competent and registered trainer.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-sm-12">
                <div className="requirement-box">
                  <p>
                    In order to repeat the final exam in the state of
                    california, if a student doesn't receive a passing score of
                    60%, they must study the course content for a minimum of 18
                    days and should reappear for the examination.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-sm-12">
                <div className="requirement-box">
                  <p>
                    ln accordance with the California Department of Real
                    Estate's rules. The candidate must learn, comprehend, and
                    research the material for a minimum of 18 days before
                    completing the final exams.
                  </p>
                </div>
              </div>
            </div>
            {/* <p className="mb-0 mt-3">
              For more information about Two-Day Crash Course, visit{" "}
              <a
                href="https://dreamforceacademy.org/two-day-crash-course"
                target="_blank"
                rel="noreferrer"
                aria-labelledby="Dreamforce Academy"
              >
                Two-Day Crash Course
              </a>{" "}
              at Dreamforce Academy.
            </p> */}
          </div>
        </motion.div>
      </section>
    </>
  );
};

export default TwoDayCrashCourse;
