import { motion } from "framer-motion";

const MLSDisclaimer = () => {
  /* UPDATE META DATA */
  const metaTitle = "MLS Disclaimer | Amberwood Real Estate";
  const metaDescription = "Our MLS Disclaimer page provides important information regarding the accuracy and reliability of property listings displayed on our website."
  const metaImage = "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png"

  document.title = metaTitle;
  document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);

  document.querySelector('meta[property="og:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[property="og:image"]').setAttribute('content', metaImage);
  document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

  document.querySelector('meta[name="twitter:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[name="twitter:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[name="twitter:image"]').setAttribute('content', metaImage);
  return (
    <>
      <section className="external-pages">
        {/* BANNER */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="page-banner mls-disclaimer"
        >
          <div className="heading-overlay">
            <div className="container">
              <h1>MLS DISCLAIMER</h1>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="content"
        >
          <div className="container">
            <p>
              Based on information from California Regional Multiple Listing
              Service, Inc. This information is for your personal,
              non-commercial use and may not be used for any purpose other than
              to identify prospective properties you may be interested in
              purchasing. The display of MLS data is usually deemed reliable but
              is NOT guaranteed accurate by the MLS. Buyers are responsible for
              verifying the accuracy of all information and should investigate
              the data themselves or retain appropriate professionals.
              Information from sources other than the Listing Agent may have
              been included in the MLS data. Unless otherwise specified in
              writing, the Broker/Agent has not and will not verify any
              information obtained from other sources. The Broker/Agent
              providing the information contained herein may or may not have
              been the Listing and/or Selling Agent.
            </p>
            <p className="mt-2">
              Based on information from CARETS as of June 17, 2020, 1:26 PM. The
              information being provided by CARETS is for the visitor’s
              personal, noncommercial use and may not be used for any purpose
              other than to identify prospective properties visitor may be
              interested in purchasing. The data contained herein is copyrighted
              by CARETS, CLAW, CRISNet MLS, i-Tech MLS, PSRMLS, and/or VCRDS and
              is protected by all applicable copyright laws. Any dissemination
              of this information is in violation of copyright laws and is
              strictly prohibited.
            </p>
            <p className="mt-2">
              Any property information referenced on this website comes from the
              Internet Data Exchange (IDX) program of CRISNet MLS and/or CARETS.
              All data, including all measurements and calculations of area, is
              obtained from various sources and has not been, and will not be,
              verified by broker or MLS. All information should be independently
              reviewed and verified for accuracy. Properties may or may not be
              listed by the office/agent presenting the information.
            </p>
            <p className="mt-2">
              The MLS™ Combined LA/Westside MLS® (CLAW): All information deemed
              reliable but not guaranteed. All properties are subject to prior
              sale, change or withdrawal. Neither listing broker(s) or
              information provider(s) shall be responsible for any typographical
              errors, misinformation, misprints and shall be held totally
              harmless. Listing(s) information is provided for consumer’s
              personal, non-commercial use and may not be used for any purpose
              other than to identify prospective properties consumers.
            </p>
            <p className="mt-2">
              Amberwood Real Estate Inc.: All information deemed reliable but
              not guaranteed. All properties are subject to prior sale, change
              or withdrawal. Neither listing broker(s) or information
              provider(s) shall be responsible for any typographical errors,
              misinformation, misprints and shall be held totally harmless.
              Listing(s) information is provided for consumer’s personal,
              non-commercial use and may not be used for any purpose other than
              to identify prospective properties consumers may be interested in
              purchasing. The data relating to real estate for sale on this
              website comes in part from the Internet Data Exchange program of
              the Multiple Listing Service. Real estate listings held by
              brokerage firms other than NRE Executives may be marked with the
              Internet Data Exchange logo and detailed information about those
              properties will include the name of the listing broker(s) when
              required by the MLS.
            </p>
          </div>
        </motion.div>
      </section>
    </>
  );
};

export default MLSDisclaimer;
