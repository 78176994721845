import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { motion } from "framer-motion";

/* ICON IMPORTS */
import CircleArrowRight from '@iconscout/react-unicons/icons/uil-arrow-circle-right';

/* CONFIG */
import { IMAGE_URL } from "../config";

const ServicesBox = (props) => {

    const ServiceBox = (props) => {
      return (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="box"
        >
          <img src={props.img} className="box-img" alt={props.serviceName} />
          {/* OVERLAY WITH DETAILS */}
          <div className="details-overlay">
            <Button className="learn-btn">
              <p>{props.serviceName}</p>
              <CircleArrowRight color="#323232" size="22" />
            </Button>
          </div>
        </motion.div>
      );
    };

    return (
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className={`services-box ${props.externalStyle}`}
      >
        <div className="container-full-width">
          <div className="row full-width">
            <div className="col-xl-4 col-lg-4 col-sm-12 rm-padding">
              <Link to="/seller-overview">
                <ServiceBox
                  img={IMAGE_URL + "images/sell-your-home.webp"}
                  serviceName="SELL YOUR HOME"
                />
              </Link>
            </div>
            <div className="col-xl-4 col-lg-4 col-sm-12 rm-padding">
              <Link to="/find-a-home">
                <ServiceBox
                  img={IMAGE_URL + "images/find-your-home.webp"}
                  serviceName="FIND YOUR HOME"
                />
              </Link>
            </div>
            <div className="col-xl-4 col-lg-4 col-sm-12 rm-padding">
              <Link to="/become-an-agent">
                <ServiceBox
                  img={IMAGE_URL + "images/become-an-agent-min.webp"}
                  serviceName="BECOME AN AGENT"
                />
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    );
};

export default ServicesBox;