/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import BlogSidebar from "../../components/blog-sidebar";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { motion } from "framer-motion";

/* IMAGES */
import BlogPlaceholder from '../../assets/blog-placeholder.webp';
import SidebarBanner from '../../assets/banners/blog-side-banner.webp';

/* ICONS */
import PreviousIcon from '@iconscout/react-unicons/icons/uil-previous';
import CloseIcon from "@iconscout/react-unicons/icons/uil-times";
import NextIcon from "@iconscout/react-unicons/icons/uil-step-forward";

/* APIS */
import { getBlogs, contactUs, getBlogCategory } from "../../service/api";

/* REDUX REQUIREMENTS */
import actions from "../../redux/actions/userAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

/* LOADING SCREEN */
import { Button } from "react-bootstrap";

/* CONFIG */
import { IMAGE_URL } from "../../config";

const BlogBoxLoading = () => {
  return (
    <div className="blog-box new-one">
      <img
        src={BlogPlaceholder}
        alt='Blog'
      />
      <div className="details">
        <Skeleton className="loading-skeleton" />
        <Skeleton className="title-loading-skeleton" />
      </div>
      <div className="time-to-read">
        <Skeleton className="time-loading-skeleton" />
      </div>
    </div>
  );
};

const BlogBox = (props) => {
  return (
    <Link
      to={"/blog-detail/" + props?.blogDetails?._id}
      style={{ textDecoration: "none" }}
    >
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="blog-box new-one"
      >
        <img src={IMAGE_URL + props.imgLink} alt={props.blogTitle} />
        <div className="details">
          <span>
            {moment(`${new Date(props?.date)}`).format("DD MMMM, YYYY")}
          </span>
          <h5>{props.blogTitle}</h5>
        </div>
        <div className="time-to-read">
          <p>10 mins</p>
        </div>
      </motion.div>
    </Link>
  );
};

const OurBlogs = () => {
   /* UPDATE META DATA */
   const metaTitle = "Blogs | Amberwood Real Estate";
   const metaDescription = "Delve into a wealth of knowledge and inspiration through our engaging real estate blogs. Our 'Blogs' page is a treasure trove of informative articles, industry insights, and expert advice."
   const metaImage = "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png"
 
   document.title = metaTitle;
   document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);
 
   document.querySelector('meta[property="og:title"]').setAttribute('content', metaTitle);
   document.querySelector('meta[property="og:description"]').setAttribute('content', metaDescription);
   document.querySelector('meta[property="og:image"]').setAttribute('content', metaImage);
   document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
 
   document.querySelector('meta[name="twitter:title"]').setAttribute('content', metaTitle);
   document.querySelector('meta[name="twitter:description"]').setAttribute('content', metaDescription);
   document.querySelector('meta[name="twitter:image"]').setAttribute('content', metaImage);

  /* GET USER STATE REDUX VARIABLE VALUES */
  const blogCategory = useSelector((state) => state?.user?.blogCategory);

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [totalDocs, setTotalDocs] = useState(0);
  const [totalPages, setTotalPages] = useState(0)
  const [hasNextPage, setHasNextPage] = useState(null);
  const [hasPrevPage, setHasPrevPage] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null)
  const [blogsData, setBlogsData] = useState([])
  const [category, setCategory] = useState(blogCategory?.categoryId ? blogCategory?.categoryId : null)
  const [categoryName, setCategoryName] = useState(blogCategory?.categoryName ? blogCategory?.categoryName : null)

  const navigate = useNavigate();
  const dispatch = useDispatch();




  useEffect(() => {
    getBlogsListFunction()
  }, []);

  // useEffect(() => {
  //   if (blogCategory) {
  //     if (blogCategory?.categoryName && blogCategory?.categoryId) {
  //       setCategory(blogCategory?.categoryId)
  //       setCategoryName(blogCategory?.categoryName)
  //     }
  //   }
  // }, [])

  useEffect(() => {
    getBlogsListFunction()
  }, [category]);

  /* GET BLOGS LISTING */
  const getBlogsListFunction = () => {
    setLoading(true);

    const obj = {};
    obj.page = page;
    obj.sizePerPage = pageSize;
    if (category) {
      obj.category = category;
    }

    getBlogs(obj).then((res) => {
      if (res?.status) {
        setBlogsData(res?.data?.docs);
        setHasNextPage(res?.data?.hasNextPage)
        setHasPrevPage(res?.data?.hasPrevPage)
        setNextPage(res?.data?.nextPage)
        setPrevPage(res?.data?.prevPage)
        setTotalDocs(res?.data?.totalDocs)
        setTotalPages(res?.data?.totalPages)
        // setT(res?.data?.totalDocs)
      }
      setLoading(false)
    })
      .catch(err => {
        console.log("err", err)
      })
      .finally(err => {
        setLoading(false)
      })

    return (() => {
      setBlogsData([])
    })
  }

  useEffect(() => {
    getBlogsListFunction()
  }, [page])

  /* PAGE CLICK */
  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    setLoading(true)
    setBlogsData([])
    setPage(selectedPage);
    console.log(`Page ${selectedPage} clicked.`);
  };

  const handleClear = () => {
    setCategory(null)
    setCategoryName(null)
    dispatch(actions.setBlogDetails(null))
  }

  return (
    <>

      <section className="our-blogs">
        {/* BANNER */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="page-banner"
        >
          <div className="heading-overlay">
            <div className="container">
              <h1>OUR BLOGS</h1>
            </div>
          </div>
        </motion.div>

        {/* BLOGS SECTION */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="blogs-list"
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-sm-12">
                <div className="list-sidebar">
                  <div className="row gy-4">
                    {/* FILTERS APPLIED BOX */}
                    {category ? (
                      <div className="filters-applied">
                        <p>Filters:</p>
                        <div className="applied-filters-list">
                          <div className="applied-filter">
                            <p>
                              <b style={{ fontWeight: "600" }}>
                                {categoryName}
                              </b>
                            </p>
                            <Button className="close-btn" onClick={handleClear}>
                              <CloseIcon size="14" color="#CD5828" />
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {loading ? (
                      <>
                        <div className="col-xl-4 col-sm-12">
                          <BlogBoxLoading />
                        </div>
                        <div className="col-xl-4 col-sm-12">
                          <BlogBoxLoading />
                        </div>
                        <div className="col-xl-4 col-sm-12">
                          <BlogBoxLoading />
                        </div>
                      </>
                    ) : (
                      blogsData.map((blog) => {
                        return (
                          <div
                            className="col-xl-4 col-lg-6 col-sm-12"
                            key={blog.id}
                          >
                            <BlogBox
                              imgLink={blog?.image}
                              blogTitle={blog?.name}
                              date={blog?.startDate}
                              blogDetails={blog}
                            />
                          </div>
                        );
                      })
                    )}
                  </div>
                  <ReactPaginate
                    className="blogs-pagination"
                    previousLabel={
                      <Button className="blog-pagination-btn">
                        <PreviousIcon color="#323232" size="20" />
                      </Button>
                    }
                    nextLabel={
                      <Button className="blog-pagination-btn">
                        <NextIcon color="#323232" size="20" />
                      </Button>
                    }
                    breakLabel={"..."}
                    pageCount={totalPages}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    activeClassName={"active"}
                  />
                </div>
              </div>

              {/* SIDEBAR */}
              <div className="col-xl-4 col-lg-4 col-sm-12">
                <div className="sidebar">
                  {/* CONNECT WITH AMBERWOOD AGENT */}
                  <div className="connect-with-agent">
                    <img
                      src={SidebarBanner}
                      alt="Connect with local Amberwood Agent"
                    />
                    <div className="overlay">
                      <div>
                        <h5>Connect with Local Amberwood Agent</h5>
                        <Link to="/our-agents">
                          <Button className="get-started">Get Started</Button>
                        </Link>
                      </div>
                    </div>
                  </div>

                  {/* CATEGORIES */}
                  <div className="blog-categories">
                    <div className="box-header">
                      <h5>Blog Categories</h5>
                    </div>
                    <div className="box-body">
                      <Link
                        className="category-link"
                        onClick={() => {
                          setCategory("644741d5caa40f2e07b6eb7e");
                          setCategoryName("Become a Real Estate Agent");
                        }}
                      >
                        Become a Real Estate Agent
                      </Link>
                      <Link
                        className="category-link"
                        onClick={() => {
                          setCategory("644741e2caa40f2e07b6eb81");
                          setCategoryName("Buyer's Agent");
                        }}
                      >
                        Buyer's Agent
                      </Link>
                      <Link
                        className="category-link"
                        onClick={() => {
                          setCategory("644741e7caa40f2e07b6eb84");
                          setCategoryName("Buying a Home");
                        }}
                      >
                        Buying a Home
                      </Link>
                      <Link
                        className="category-link"
                        onClick={() => {
                          setCategory("644741eccaa40f2e07b6eb87");
                          setCategoryName("Buying and Selling Homes");
                        }}
                      >
                        Buying and Selling Homes
                      </Link>
                      <Link
                        className="category-link"
                        onClick={() => {
                          setCategory("644741f0caa40f2e07b6eb8a");
                          setCategoryName("Home Inspection");
                        }}
                      >
                        Home Inspection
                      </Link>
                      <Link
                        className="category-link"
                        onClick={() => {
                          setCategory("644741f5caa40f2e07b6eb8d");
                          setCategoryName("Listing Agent");
                        }}
                      >
                        Listing Agent
                      </Link>
                      <Link
                        className="category-link"
                        onClick={() => {
                          setCategory("644741fbcaa40f2e07b6eb90");
                          setCategoryName("Real Estate Agent");
                        }}
                      >
                        Real Estate Agent
                      </Link>
                      <Link
                        className="category-link"
                        onClick={() => {
                          setCategory("64474200caa40f2e07b6eb93");
                          setCategoryName("Sell Your Home");
                        }}
                      >
                        Sell Your Home
                      </Link>
                      <Link
                        className="category-link"
                        onClick={() => {
                          setCategory("64474204caa40f2e07b6eb96");
                          setCategoryName("Selling Real Estate");
                        }}
                      >
                        Selling Real Estate
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </section>
    </>
  );
};

export default OurBlogs;