import React, { useState } from "react";
import { useEffect } from "react";
import ReactApexChart from 'react-apexcharts';

const MortgageChart = (props) => {
    const [state, setState] = useState({
      series: [100],
      /* series: [44, 55, 41, 17, 15], */
      options: {
        chart: {
          type: "donut",
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        labels: [
          "Principal and Interest",
          "Taxes",
          "Homeowners Insurance",
          "Private Mortgage Insurance",
          "HOA Fees",
        ],
        legend: {
          show: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 350,
                height: 300,
                fontFamily: "Figtree, sans-serif",
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    });

    useEffect(() => {
      setState({
        series: [
          props.principalAndInterest,
          props.taxes,
          props.insurance,
          props.pmi,
          props.hoaFees,
        ],
      });
    }, [props]);

    return (
        <div id="pie-chart">
            <ReactApexChart height={270} options={state.options} series={state.series} type="donut" />
        </div>
    );
};

export default MortgageChart;