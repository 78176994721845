import React, { useEffect } from 'react';
import { ThemeProvider } from 'react-bootstrap';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import store from '../redux/store';
import { Provider } from 'react-redux';

/* LAYOUT */
import Layout from '../layout';
import FullWidthLayout from '../layout/index-full-width';

/* PAGES */
import FindAHome from '../pages/for-buyers/find-a-home';
import Homepage from '../pages/home';
import OurTeam from '../pages/our-team';
import BuyerOverview from '../pages/buyer-overview';
import PropertyDetails from '../pages/for-buyers/property-detail';
import OurAgents from '../pages/our-agents/index';
import AgentDetails from '../pages/our-agents/agent-detail';
import BlogDetail from '../pages/our-blogs/blog-detail';
import MortgageCalculator from '../pages/mortgage-calculator';
import HomeEvaluation from '../pages/home-evaluation';
import BecomeAnAgent from '../pages/become-an-agent';
import AboutUs from '../pages/about-us';
import Events from '../pages/events';
import SellerOverview from '../pages/seller-overview';
import OurBlogs from '../pages/our-blogs';
import RealEstatePracticeCourse from '../pages/real-estate-practice-course';
import RealEstatePrinciplesCourse from '../pages/real-estate-principles-course';
import RealEstateFinanceCourse from '../pages/real-estate-finance-course';
import TwoDayCrashCourse from '../pages/two-day-crash-course';
import PrivacyPolicy from '../pages/privacy-policy';
import MLSDisclaimer from '../pages/mls-disclaimer';
import FairHousingStatement from '../pages/fair-housing';
import Accessibility from '../pages/accessibility';
import FeaturedListings from '../pages/for-buyers/featured-listings';
import HomeEvaluationPage from '../pages/home-evaluation-page';

/* STYLES */
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/global.scss";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const AppRoutes = () => {
  return (
    <Provider store={store}>
      <ThemeProvider breakpoints={["xl", "lg", "md", "sm"]} minBreakpoint="sm">
        <div className="app">
          <Router>
            <ScrollToTop />
            <Routes>
              <Route
                path="/"
                element={
                  <Layout>
                    <Homepage />
                  </Layout>
                }
              />
              <Route
                path="/find-a-home"
                element={
                  <FullWidthLayout>
                    <FindAHome />
                  </FullWidthLayout>
                }
              />
              <Route
                path="/our-office"
                element={
                  <Layout>
                    <OurTeam />
                  </Layout>
                }
              />
              <Route
                path="/buyer-overview"
                element={
                  <Layout>
                    <BuyerOverview />
                  </Layout>
                }
              />
              <Route
                path="/property-detail"
                element={
                  <Layout>
                    <PropertyDetails />
                  </Layout>
                }
              />
              <Route
                path="/property-detail/:mlsId/:mlsKey"
                element={
                  <Layout>
                    <PropertyDetails />
                  </Layout>
                }
              />
              <Route
                path="/our-agents"
                element={
                  <Layout>
                    <OurAgents />
                  </Layout>
                }
              />
              <Route
                path="/agent-detail/:id"
                element={
                  <Layout>
                    <AgentDetails />
                  </Layout>
                }
              />
              <Route
                path="/mortgage-calculator"
                element={
                  <Layout>
                    <MortgageCalculator />
                  </Layout>
                }
              />
              <Route
                path="/home-evaluation"
                element={
                  <Layout>
                    <HomeEvaluationPage />
                  </Layout>
                }
              />
              <Route
                path="/become-an-agent"
                element={
                  <Layout>
                    <BecomeAnAgent />
                  </Layout>
                }
              />
              <Route
                path="/about-us"
                element={
                  <Layout>
                    <AboutUs />
                  </Layout>
                }
              />
              <Route
                path="/featured-listings"
                element={
                  <FullWidthLayout>
                    <FeaturedListings />
                  </FullWidthLayout>
                }
              />
              <Route
                path="/events"
                element={
                  <Layout>
                    <Events />
                  </Layout>
                }
              />
              <Route
                path="/seller-overview"
                element={
                  <Layout>
                    <SellerOverview />
                  </Layout>
                }
              />
              <Route
                path="/blogs"
                element={
                  <Layout>
                    <OurBlogs />
                  </Layout>
                }
              />
              <Route
                path="/blog-detail/:id"
                element={
                  <Layout>
                    <BlogDetail />
                  </Layout>
                }
              />
              <Route
                path="/real-estate-practice-course"
                element={
                  <Layout>
                    <RealEstatePracticeCourse />
                  </Layout>
                }
              />
              <Route
                path="/real-estate-principles-course"
                element={
                  <Layout>
                    <RealEstatePrinciplesCourse />
                  </Layout>
                }
              />
              <Route
                path="/real-estate-finance-course"
                element={
                  <Layout>
                    <RealEstateFinanceCourse />
                  </Layout>
                }
              />
              <Route
                path="/two-day-crash-course"
                element={
                  <Layout>
                    <TwoDayCrashCourse />
                  </Layout>
                }
              />
              <Route
                path="/privacy-policy"
                element={
                  <Layout>
                    <PrivacyPolicy />
                  </Layout>
                }
              />
              <Route
                path="/mls-disclaimer"
                element={
                  <Layout>
                    <MLSDisclaimer />
                  </Layout>
                }
              />
              <Route
                path="/fair-housing-statement"
                element={
                  <Layout>
                    <FairHousingStatement />
                  </Layout>
                }
              />
              <Route
                path="/accessibility"
                element={
                  <Layout>
                    <Accessibility />
                  </Layout>
                }
              />
              <Route
                path="/home-evaluation-old"
                element={
                  <Layout>
                    <HomeEvaluation />
                  </Layout>
                }
              />
            </Routes>
          </Router>
        </div>
      </ThemeProvider>
    </Provider>
  );
};

export default AppRoutes;