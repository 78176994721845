/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { useFormik } from "formik";
import * as yup from "yup";
import toast, { Toaster } from 'react-hot-toast';
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

/* EXTERNAL STYLES */
import "react-phone-number-input/style.css";

/* IMPORT APIS */
import { getAgentDetailsById, contactUs } from "../../service/api";

/* ICON IMPORTS */
import FacebookIcon from '@iconscout/react-unicons/icons/uil-facebook-f';
import InstagramIcon from "@iconscout/react-unicons/icons/uil-instagram";
import LinkedinIcon from "@iconscout/react-unicons/icons/uil-linkedin";
import TwitterIcon from "@iconscout/react-unicons/icons/uil-twitter";
import GlobeIcon from "@iconscout/react-unicons/icons/uil-globe";

/* CONFIG */
import { IMAGE_URL } from "../../config";

/* LOADING SCREEN */
import AgentDetailPageLoading from "../../components/loading-agent-details"

const AgentDetails = () => {

  const { id } = useParams()
  const getId = id?.split('&')
  const { selectedAgentId } = useSelector((state) => state.user);
  console.log('selectedAgentId: ', selectedAgentId);
  const [loading, setLoading] = useState(true);
  const [formSubmit, setFormSubmit] = useState(false);
  const [agentDetails, setAgentDetails] = useState(null)

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email().required("Email is required"),
    phone: yup.string().required("Phone Number is required"),
    message: yup.string().required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
      type: "CONTACTUS",
      agentId: getId[0],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setFormSubmit(true)
      contactUs(values)
        .then((res) => {
          if (res?.status) {
            console.log(res?.message);
            toast.success(res?.message);
            formik.resetForm();
          } else {
            console.log(res?.message);
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message);
        }).finally(res => {
          setFormSubmit(false)
        });
    },
  });

  useEffect(() => {
    getAgentDetails();
  }, [getId[0]]);

  const getAgentDetails = () => {
    setLoading(true);
    getAgentDetailsById(getId[0])
      .then((res) => {
        if (res?.status) {
          setAgentDetails(res?.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      })
      .finally((err) => {
        setLoading(false);
      });

    return (() => {
      setAgentDetails(null)
    })
  }
  useEffect(() => {
    if (agentDetails?.firstName !== undefined && agentDetails?.lastName !== undefined) {
      /* UPDATE META DATA */

      const metaTitle = agentDetails?.firstName + " " + agentDetails?.lastName + " | Amberwood Real Estate";
      const metaDescription = "Discover the expertise and professionalism of our real estate agent, " + agentDetails?.firstName + " " + agentDetails?.lastName
      const metaImage = "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png"

      document.title = metaTitle;
      document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);

      document.querySelector('meta[property="og:title"]').setAttribute('content', metaTitle);
      document.querySelector('meta[property="og:description"]').setAttribute('content', metaDescription);
      document.querySelector('meta[property="og:image"]').setAttribute('content', metaImage);
      document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

      document.querySelector('meta[name="twitter:title"]').setAttribute('content', metaTitle);
      document.querySelector('meta[name="twitter:description"]').setAttribute('content', metaDescription);
      document.querySelector('meta[name="twitter:image"]').setAttribute('content', metaImage);
    }
  }, [agentDetails])
  return (
    <section className="agent-details">
      {/* TOP BOXES */}
      <Toaster position="top-right" reverseOrder={false} />
      {loading ? (
        <AgentDetailPageLoading />
      ) : (
        <>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            className="top-boxes"
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-sm-12">
                  <div className="agent-profile-box">
                    <div className="row h-100 align-items-center">
                      <div className="col-xl-4 col-sm-12">
                        <div className="d-flex align-items-center justify-content-center">
                          <img
                            src={
                              agentDetails?.image
                                ? IMAGE_URL + agentDetails?.image
                                : "https://storage.googleapis.com/cdn-amberwoodre/images/agent-placeholder.png"
                            }
                            className="agent-image"
                            alt={`${agentDetails?.firstName + agentDetails?.lastName
                              }`}
                          />
                        </div>
                      </div>
                      <div className="col-xl-8 col-sm-12">
                        <div className="agent-details">
                          <div className="details-header">
                            <h1>
                              {agentDetails?.firstName} {agentDetails?.lastName}
                            </h1>

                            <div className="d-flex align-items-center social-links">
                              <a
                                href={
                                  agentDetails?.social?.fbUrl
                                    ? agentDetails?.social?.fbUrl
                                    : "https://www.facebook.com/amberwoodre"
                                }
                                target="_blank"
                                className="social-icon facebook"
                                aria-label="Facebook"
                                rel="noreferrer"
                              >
                                <FacebookIcon color="#323232" size="22" />
                              </a>
                              <a
                                href={
                                  agentDetails?.social?.instaUrl
                                    ? agentDetails?.social?.instaUrl
                                    : "https://www.instagram.com/amberwoodreinc/"
                                }
                                target="_blank"
                                className="social-icon instagram"
                                aria-label="Instagram"
                                rel="noreferrer"
                              >
                                <InstagramIcon color="#323232" size="22" />
                              </a>

                              {agentDetails?.social?.linkedInUrl ? (
                                <a
                                  href={agentDetails?.social?.linkedInUrl}
                                  target="_blank"
                                  className="social-icon instagram"
                                  aria-label="Linkedin"
                                  rel="noreferrer"
                                >
                                  <LinkedinIcon color="#323232" size="22" />
                                </a>
                              ) : (
                                false
                              )}
                              {agentDetails?.social?.twitterUrl ? (
                                <a
                                  href={agentDetails?.social?.twitterUrl}
                                  target="_blank"
                                  className="social-icon instagram"
                                  aria-label="Twitter"
                                  rel="noreferrer"
                                >
                                  <TwitterIcon color="#323232" size="22" />
                                </a>
                              ) : (
                                false
                              )}
                              {agentDetails?.social?.webUrl ? (
                                <a
                                  href={agentDetails?.social?.webUrl}
                                  target="_blank"
                                  className="social-icon instagram"
                                  aria-label="Website URL"
                                  rel="noreferrer"
                                >
                                  <GlobeIcon color="#323232" size="22" />
                                </a>
                              ) : (
                                false
                              )}
                            </div>
                          </div>
                          <div className="details-body">
                            <p>
                              <span>Agent License:</span> DRE#{" "}
                              {agentDetails?.licence}
                            </p>
                            <p>
                              <span>Phone number:</span> {agentDetails?.mobile}
                            </p>
                            <p>
                              <span>Email Address:</span> {agentDetails?.email}
                            </p>
                            <div className="cta-btns">
                              <a href={"mailto:" + agentDetails?.email}>
                                <Button className="send-email">
                                  SEND EMAIL
                                </Button>
                              </a>
                              <a href={"tel:" + agentDetails?.mobile}>
                                <Button className="call-now">CALL NOW</Button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12">
                  <form className="inquiry-form" onSubmit={formik.handleSubmit}>
                    <h2>Schedule a call with me</h2>
                    <div className="row">
                      <div className="col-xl-6 col-sm-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className={`form-control ${formik.errors.name && formik.touched.name
                              ? "validation-border"
                              : ""
                              }`}
                            placeholder="Name *"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-sm-12">
                        <div className="form-group">
                          <input
                            type="email"
                            className={`form-control ${formik.errors.email && formik.touched.email
                              ? "validation-border"
                              : ""
                              }`}
                            placeholder="Email Address *"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            name="email"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <PhoneInput
                        placeholder="Phone *"
                        type="text"
                        defaultCountry="US"
                        name="phone"
                        value={formik.values.phone}
                        onChange={(phone) =>
                          formik.setFieldValue("phone", phone)
                        }
                        className={`phone-number-select ${formik.errors.phone && formik.touched.phone
                          ? "validation-border"
                          : ""
                          }`}
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        rows={3}
                        className={`textarea-control ${formik.errors.message && formik.touched.message
                          ? "validation-border"
                          : ""
                          }`}
                        name="comments"
                        value={formik.values.message}
                        onChange={(message) =>
                          formik.setFieldValue("message", message.target.value)
                        }
                        placeholder="Short message *"
                      />
                    </div>
                    {formSubmit ? (
                      <Button className="submit-btn">PLEASE WAIT</Button>
                    ) : (
                      <Button className="submit-btn" type="submit">
                        SEND MESSAGE
                      </Button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </motion.div>

          {/* ABOUT AGENT */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            className={agentDetails?.about ? "about-agent" : "d-none"}
          >
            <div className="container">
              <div className="bio-box">
                <div className="box-header">
                  <h2 style={{ textTransform: "uppercase" }}>
                    ABOUT {agentDetails?.firstName} {agentDetails?.lastName}
                  </h2>
                  <div className="underline" />
                </div>
                <div className="box-body">
                  {ReactHtmlParser(agentDetails?.about)}
                </div>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </section>
  );
};

export default AgentDetails;