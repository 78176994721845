import React from "react";

/* IMPORT TOASTER FOR NOTIFICATION */
import { ToastContainer } from "react-toastify";

/* HEADER AND FOOTER */
import Header from "./header";
import Footer from "./footer";

const Layout = ({ children }) => {
    return (
        <main>
            <Header />
            <ToastContainer />
            <div className="main">{children}</div>
            <Footer />
        </main>
    );
};

export default Layout;