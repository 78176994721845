/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment-timezone";
import { Button, Card, Modal, Offcanvas } from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import PhoneInput from "react-phone-number-input";
import { useFormik } from "formik";
import * as yup from "yup";
import toast, { Toaster } from 'react-hot-toast';
import ReactShowMoreText from "react-show-more-text";
import { motion } from "framer-motion";

/* PACKAGE STYLES */
import "react-phone-number-input/style.css";

/* ICON IMPORTS */
import CloseIcon from "@iconscout/react-unicons/icons/uil-times";

/* API */
import { getEvents, sendRSVPDetails } from "../../service/api";

const localizer = momentLocalizer(moment);

/* EVENT BOX */
const EventBox = (props) => {
  const [showRSVPModal, setShowRSVPModal] = useState(false);
  const eventDetail = props?.eventDetails?.data;
  let day = moment(`${new Date(eventDetail?.startDate)}`).format('DD MMMM, YYYY');

  const toggleViewFunction = () => {
    setShowRSVPModal(!showRSVPModal)
  }
  return (
    <>
      <Card
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="event-box"
      >
        <Card.Body className="event-boxbody">
          <div className="time-details">
            <p>{day}</p>
            <p>
              {" "}
              {moment(eventDetail?.startTime, ["HH:mm"]).format(
                "h:mm A"
              )} - {moment(eventDetail?.endTime, ["HH:mm"]).format("h:mm A")}
            </p>
          </div>
          <div className="event-details">
            <h2>{eventDetail?.name}</h2>
            <ReactShowMoreText
              lines={3}
              more="Show more"
              less="Show less"
              className="event-content"
              expanded={false}
            >
              {eventDetail?.discription}
            </ReactShowMoreText>
            {/* <p>{eventDetail?.discription}</p> */}
            <Button
              className="rsvp-btn"
              onClick={(e) => setShowRSVPModal(!showRSVPModal)}
            >
              RSVP
            </Button>
          </div>
        </Card.Body>
      </Card>

      {/* RSVP MODAL */}
      <RSVPModel
        eventDetails={eventDetail}
        isShow={showRSVPModal}
        toggleViewFunction={toggleViewFunction}
      />
    </>
  );
};

/* RSVP FORM MODAL */
const RSVPModel = (props) => {
  const [loading, setLoading] = useState(false)

  /* VALIDATION SCHEMA */
  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email().required("Email is required"),
    phone: yup.string().required("Phone number is required"),
    noOfPeopleAttending: yup.number().required("Please mention no. of people attending the event"),
  });

  /* FORM VALUUES HANDLING */
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      noOfPeopleAttending: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const obj = {
        "name": values.name,
        "email": values.email,
        "phone": values.phone,
        "noOfPeopleAttending": values.noOfPeopleAttending,
        "event": props?.eventDetails?._id
      }
      setLoading(true)
      sendRSVPDetails(obj)
        .then((res) => {
          if (res?.status) {
            console.log(res?.message);
            toast.success(res?.message);
            formik.resetForm();
          } else {
            console.log(res?.message);
            toast.error(res?.message?.details?.[0]?.message);
          }
        })
        .catch((e) => {
          console.log("error", e)
          toast.error(e?.response?.data?.message?.details?.[0]?.message);
        }).finally(res => {
          setLoading(false)
        });
    }
  });

  return (
    <Modal
      show={props?.isShow}
      centered
      size="md"
      onHide={() => props?.toggleViewFunction()}
    >
      <Toaster position="top-right" reverseOrder={false} />
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body className="rsvp-modal-body">
          <div className="form-group">
            <label className="form-label">
              Name <span>*</span>
            </label>
            <input
              type="text"
              className={`form-control ${formik.errors.name && formik.touched.name
                ? "validation-border"
                : ""
                }`}
              name="name"
              placeholder="Name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </div>
          <div className="form-group">
            <label className="form-label">
              Email <span>*</span>
            </label>
            <input
              type="email"
              className={`form-control ${formik.errors.email && formik.touched.email
                ? "validation-border"
                : ""
                }`}
              placeholder="Email Address"
              value={formik.values.email}
              onChange={formik.handleChange}
              name="email"
            />
          </div>
          <div className="form-group">
            <label className="form-label">
              Phone <span>*</span>
            </label>
            <PhoneInput
              placeholder="Phone Number"
              defaultCountry="US"
              className={`phone-number-select ${formik.errors.phone && formik.touched.phone
                ? "validation-border"
                : ""
                }`}
              name="phone"
              value={formik.values.phone}
              onChange={(phone) => formik.setFieldValue("phone", phone)}
            />
          </div>
          <div className="form-group">
            <label className="form-label">
              No. of People Attending Event <span>*</span>
            </label>
            <input
              type="number"
              className={`form-control ${formik.errors.noOfPeopleAttending && formik.touched.noOfPeopleAttending
                ? "validation-border"
                : ""
                }`}
              name="noOfPeopleAttending"
              placeholder="No. of People attending the event"
              value={formik.values.noOfPeopleAttending}
              onChange={formik.handleChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="rsvp-modal-footer">
          <Button
            className="cancel-button"
            onClick={(e) => props?.toggleViewFunction()}
          >
            Cancel
          </Button>
          {loading ? (
            <Button className="rsvp-button">Please wait</Button>
          ) : (
            <Button className="rsvp-button" type="submit">
              RSVP
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
}

/* CALENDAR EVENT COMPONENT */
const CalendarEventBox = (props) => {
  const [isPopoverOpen, setPopOveropen] = useState(false);
  const [showRSVPModal, setShowRSVPModal] = useState(false);
  const eventDetail = props?.data?.event?.data;

  let time = moment(`${new Date(eventDetail?.startDate)}`).format('DD MMMM, YYYY');

  /* COMPARE DATES */
  let eventDate = new Date(eventDetail?.startDate);
  let now = new Date();

  const toggleViewFunction = () => {
    setShowRSVPModal(!showRSVPModal)
  }

  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        positions={["right", "left", "top", "bottom"]}
        padding={0}
        reposition={true}
        containerClassName="popover-css"
        content={() => (
          <motion.Card
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            className="event-box"
            aria-label={`${props?.title}`}
          >
            <Card.Body className="event-boxbody">
              <div className="time-details">
                <p>{time}</p>
                <p>
                  {moment(eventDetail?.startTime, ["HH:mm"]).format("h:mm A")} -{" "}
                  {moment(eventDetail?.endTime, ["HH:mm"]).format("h:mm A")}{" "}
                </p>
              </div>
              <div className="event-details">
                <h2>{props?.title}</h2>
                <p>{eventDetail?.discription}</p>
                <Button
                  className={`rsvp-btn ${eventDate.setHours(0, 0, 0, 0) < now.setHours(0, 0, 0, 0)
                      ? "d-none"
                      : "d-block"
                    }`}
                  onClick={(e) => setShowRSVPModal(!showRSVPModal)}
                >
                  RSVP
                </Button>
                <RSVPModel
                  eventDetails={eventDetail}
                  isShow={showRSVPModal}
                  toggleViewFunction={toggleViewFunction}
                />
              </div>
            </Card.Body>
            <Button
              className="close-btn"
              onClick={(e) => setPopOveropen(!isPopoverOpen)}
            >
              <CloseIcon size="16" color="#323232" />
            </Button>
          </motion.Card>
        )}
      >
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className={`calendar-event-box ${eventDate.setHours(0, 0, 0, 0) < now.setHours(0, 0, 0, 0)
              ? "past"
              : ""
            }`}
          onClick={(e) => setPopOveropen(!isPopoverOpen)}
        >
          <p>{props.title}</p>
        </motion.div>
      </Popover>
    </>
  );
};

const Events = () => {
  /* UPDATE META DATA */
  const metaTitle = "Events | Amberwood Real Estate";
  const metaDescription = "Discover a calendar of insightful seminars, webinars, and networking events tailored to industry professionals and enthusiasts alike. Stay ahead of the curve with expert speakers sharing valuable market insights, trends, and strategies."
  const metaImage = "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png"

  document.title = metaTitle;
  document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);

  document.querySelector('meta[property="og:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[property="og:image"]').setAttribute('content', metaImage);
  document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

  document.querySelector('meta[name="twitter:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[name="twitter:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[name="twitter:image"]').setAttribute('content', metaImage);

  const [offCanvasPosts, setOffCanvasPosts] = useState(false);
  const [offCanvasEvents, setOffCanvasEvents] = useState(null);
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState(moment(new Date()).subtract(1, 'months').endOf('month').tz('America/Los_Angeles').format('MM/DD/YYYY'));
  const [endDate, setEndDate] = useState(moment(new Date()).add(1, 'months').startOf('month').tz('America/Los_Angeles').format('MM/DD/YYYY'));
  const [data, setData] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [MLSLoading, setMLSLoading] = useState(false);

  const getEventData = () => {
    setMLSLoading(true);
    let obj = {
      startDate: startDate,
      endDate: endDate,
      addedBy: "642d39d07172294726d81a62"
    };
    getEvents(obj)
      .then((res) => {
        if (res.status) {
          setData(res?.data);
        }
      })
      .finally((res) => {
        setMLSLoading(false);
      });
  };

  useEffect(() => {
    getEventData();
  }, [startDate, endDate]);

  useEffect(() => {

    
    const timezone = 'America/Los_Angeles'; // Replace with the desired time zone
    
    // Convert the todayDate string to a Moment object with the specified time zone
    const todayMoment = moment.tz(date, 'MM/DD/YYYY', timezone);
    
    // Get the start date of the month
    const startDateOfMonth = todayMoment.clone().startOf('month');
    
    // Get the end date of the month
    const endDateOfMonth = todayMoment.clone().endOf('month');
    

    setStartDate(startDateOfMonth.format('MM/DD/YYYY'))
    setEndDate(endDateOfMonth.format('MM/DD/YYYY'))
  }, [date])



  function userExists(username) {
    return upcomingEvents.some(function (el) {
      return el.id === username;
    });
  }


  useEffect(() => {
    if (data.length > 0) {
      let tempArray = [];
      data.forEach(element => {
        if (new Date(element?.startDate) > new Date()) {

          if (!userExists(element?._id)) {
            setUpcomingEvents((current) => [
              ...current,
              {
                id: element?._id,
                title: element?.name,
                start: moment(new Date(element?.startDate + ' ' + element?.startTime), 'YYYY-MM-DD HH:mm:ss').tz('America/Los_Angeles').format(),
                end: moment(new Date(element?.endDate + ' ' + element?.endTime), 'YYYY-MM-DD HH:mm:ss').tz('America/Los_Angeles').format(),
                data: element
              },
            ]);
          }
        }
        tempArray.push({
          id: element?._id,
          title: element?.name,
          start: moment(new Date(element?.startDate + ' ' + element?.startTime), 'YYYY-MM-DD HH:mm:ss').tz('America/Los_Angeles').format(),
          end: moment(new Date(element?.endDate + ' ' + element?.endTime), 'YYYY-MM-DD HH:mm:ss').tz('America/Los_Angeles').format(),
          data: element
        })
      })
      setEventsList(tempArray)


    } else {
      setEventsList([])
    }

  }, [data])

  const CalendarTodayButton = () => {
    const decrement = () => {
      setDate(moment(date).add(-1, "month"));
    };

    const increment = () => {
      setDate(moment(date).add(1, "month"));
    };

    return (
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="date-week-switcher"
      >
        <div className="left-section">
          <Button
            color="primary"
            className="previous-switcher"
            aria-label="calendar-previous-switcher"
            onClick={decrement}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="0.8em"
              viewBox="0 0 24 24"
              width="0.7em"
              fill="#6C757D"
              className="svg-icon"
            >
              <path d="M0 0h24v24H0V0z" fill="none" opacity={0.87} />
              <path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z" />
            </svg>
          </Button>
        </div>
        <div className="center-section">
          <h2>{moment(date).format("MMMM YYYY")}</h2>
          <p>
            {moment(date).format("dddd")}, {moment(date).format("DD MMM YYYY")}
          </p>
        </div>
        <div className="right-section">
          <Button
            color="primary"
            className="next-switcher"
            aria-label="calendar-next-switcher"
            onClick={increment}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="0.8em"
              viewBox="0 0 24 24"
              width="0.7em"
              fill="#6C757D"
              className="svg-icon"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M6.23 20.23L8 22l10-10L8 2 6.23 3.77 14.46 12z" />
            </svg>
          </Button>
        </div>
      </motion.div>
    );
  };

  return (
    <section className="events">
      <div className="container">
        <div className="row">
          <div className="col-xl-4 col-sm-12">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              className="upcoming-events"
            >
              <h1>UPCOMING EVENTS</h1>
              <div className="events-list">
                {console.log("upcomingEvents", upcomingEvents)}
                {upcomingEvents.length > 0 ? (
                  upcomingEvents?.map((element, index) => {
                    console.log(element);
                    console.log(new Date());
                    return new Date(element?.start) > new Date() ? (
                      <EventBox key={index} eventDetails={element} />
                    ) : (
                      false
                    );
                  })
                ) : (
                  <div className="no-upcoming-events">
                    <p>No Upcoming Events</p>
                  </div>
                )}
              </div>
            </motion.div>
          </div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            className="col-xl-8 col-sm-12"
          >
            <CalendarTodayButton />
            <Calendar
              localizer={localizer}
              startAccessor="start"
              endAccessor="end"
              events={eventsList}
              className="events-calendar"
              toolbar={false}
              date={date}
              onNavigate={(_date) => setDate(_date)}
              dayLayoutAlgorithm="no-overlap"
              views={["month"]}
              defaultView={Views.MONTH}
              aria-labelledby="Monthly Calendar with Events"
              onShowMore={(event) => (
                <>
                  {setOffCanvasPosts(!offCanvasPosts)}
                  {setOffCanvasEvents(event)}
                </>
              )}
              components={{
                event: (event) => (
                  <CalendarEventBox title={event.title} data={event} />
                ),
              }}
            />
          </motion.div>
        </div>

        {/* SHOW ALL EVENTS */}
        <Offcanvas
          placement="end"
          show={offCanvasPosts}
          onHide={() => (
            <>
              {setOffCanvasPosts(!offCanvasPosts)}
              {setOffCanvasEvents(null)}
            </>
          )}
        >
          <Offcanvas.Header className="all-events-header" closeButton>
            <Offcanvas.Title>
              <h5>All Events</h5>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="all-events-body">
            {offCanvasEvents
              ? offCanvasEvents.length > 0
                ? offCanvasEvents?.map((element, index) => {
                  return <EventBox key={index} eventDetails={element} />;
                })
                : false
              : false}
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </section>
  );
};

export default Events;
