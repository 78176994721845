import React from "react";
import { motion } from "framer-motion";

/* CONFIG */
import { IMAGE_URL } from '../../config';

const BuyerOverview = () => {

  /* UPDATE META DATA */
  const metaTitle = "Buyer Overview | Amberwood Real Estate";
  const metaDescription = "Gain insights into the buying process, learn about market trends, and access a vast selection of properties that match your preferences. Empower yourself as a buyer and embark on a successful journey towards finding your dream home."
  const metaImage = "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png"

  document.title = metaTitle;
  document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);

  document.querySelector('meta[property="og:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[property="og:image"]').setAttribute('content', metaImage);
  document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

  document.querySelector('meta[name="twitter:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[name="twitter:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[name="twitter:image"]').setAttribute('content', metaImage);
  
  return (
    <>
      <section className="buyer-overview">
        {/* BANNER */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="page-banner"
        >
          <div className="heading-overlay">
            <div className="container">
              <h1>BUYER OVERVIEW</h1>
            </div>
          </div>
        </motion.div>

        {/* OUR GOALS ALIGN WITH YOUR GOALS */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="content"
        >
          <div className="container">
            <h2>Our Goals Align With Your Goals</h2>
            <p>
              For many, buying a house is a lifetime goal. It’s also likely
              going to be one of the biggest — if not the biggest — purchases
              you’ll ever make. This means that the decision to become a
              homeowner is a big one. Unfortunately, many first-time
              homebuyers don’t know where to begin. The home buying process as
              a whole can be a lot more complicated than meets the eye. That’s
              why it’s so important that you find a reputable buyer’s agent
              that you can trust and rely on to help guide you through the
              process.
            </p>
            <p>
              <b>
                Here at Amberwood Real Estate Inc., we share a common goal: to
                help you become a homeowner.
              </b>
            </p>
            <p>
              We want to make sure that you find a home that fits all of your
              needs and that fits within your budget. To do this, we offer a
              number of home buying services to help simplify the process.
              These services include:
            </p>
            <ul className="content-list">
              <li>
                Offering a personal consultation to determine exactly what
                your needs are and what you’re looking for.
              </li>
              <li>
                Making it easy for you to search listings from the convenience
                of your own home.
              </li>
              <li>
                Notifying you about new listings that match your specific
                criteria as soon as they become available.
              </li>
              <li>
                Providing you with real estate market watch updates for
                listings in the area you’re interested in.
              </li>
              <li>
                Giving you access to extensive home buying resources, from
                tips and checklists to free reports.
              </li>
              <li>
                Providing you with information about communities and utilities
                in areas you’re interested in.
              </li>
              <li>
                Providing expert financial advice on home loans, mortgages,
                interest, and tax benefits.
              </li>
            </ul>
          </div>
        </motion.div>

        {/* FIND REAL ESTATE AGENT */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="find-real-estate-agent"
        >
          <div className="container">
            <div className="box">
              <div className="row align-items-center">
                <div className="col-xl-6 col-sm-12">
                  <div className="overview-details">
                    <h2>Find a Real Estate Agent You Can Trust</h2>
                    <p>
                      Searching for the right home can be an overwhelming and
                      stressful process, especially if you attempt to do so on
                      your own. First-time homebuyers often think that finding
                      a house is easy due to the fact that properties on the
                      market are listed online. However, a lot more goes into
                      the home buying process than just a home search. Trying
                      to buy a home on your own is going to end up becoming a
                      time-consuming and stressful task, and there’s a good
                      chance that without sound advice, you might end up
                      overpaying for a property in an area where the market is
                      on the downswing.
                    </p>
                    <p>
                      It’s why you’ll want to find a knowledgeable real estate
                      agent to work with that you can trust. However, not all
                      real estate agents have your best interests in mind.
                      Some agents only work part-time or work full time but
                      take on too many clients, meaning that they can’t always
                      be reached. You need to find an agent that is capable of
                      providing prompt service, personal guidance,
                      professional competence, and who is responsive to all of
                      your real estate needs.
                    </p>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12">
                  <img
                    src={IMAGE_URL + "images/real-estate-agent-trust.webp"}
                    alt="Find a Real Estate Agent You Can Trust"
                  />
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="content"
        >
          <div className="container">
            <h2>What is a Buyer’s Agent?</h2>
            <p>
              A buyer’s agent is a real estate agent that represents the
              buyer. Generally, they are paid on commission, which means that
              they are only paid if they help you find a home that you end up
              purchasing. Their commission will typically come out of the
              sales price that you pay.
            </p>
            <p>
              Having a real estate agent working on your behalf is incredibly
              beneficial. A good buyer’s agent will help with the following:
            </p>
            <ul className="content-list">
              <li>
                They will speak to you in length to determine what your wants
                and needs are, from where you want to live to how many rooms
                your house needs to have and much more. They can help you
                figure out exactly what you can afford based on your budget.
              </li>
              <li>
                They will provide you with basic financial advice, such as
                information about the costs of homeownership to what the
                mortgage process entails.
              </li>
              <li>
                They will use their vast network to identify properties that
                match what you’re looking for and that are within your budget.
              </li>
              <li>
                They will help you determine what the house is worth and what
                to offer for it.
              </li>
              <li>
                They will be able to negotiate with the seller or the seller’s
                agent to help you get the best possible deal.
              </li>
              <li>
                Once you have found a home that you want to buy, they will
                guide you through the entire closing process, which can be
                quite complicated.
              </li>
            </ul>
            <h2 className="mt-3">Helpful Tips for Buying a Home</h2>
            <p>
              The following are just some of the tips that we give to the
              buyers that we work with here at Amberwood Real Estate Inc.:
            </p>
            <ul className="content-list">
              <li>
                Increase your credit score to lower the interest rate on your
                mortgage.
              </li>
              <li>
                Get pre-approved for a mortgage so that you’ll know the exact
                price point that you can afford.
              </li>
              <li>
                Do not open new accounts (such as loans or credit cards)
                during the mortgage approval process.
              </li>
              <li>
                Compare lenders to find the best terms (we can help you find
                the right lender for you).
              </li>
            </ul>
            <h2 className="mt-3">
              Find Your Buyer’s Agent at Amberwood Real Estate Inc.
            </h2>
            <p>
              If you’ve made the decision to buy a house, it’s time to find a
              real estate that you can trust and rely on. Here at Amberwood
              Real Estate, we have built a reputation as one of the best real
              estate agencies in the area. To schedule a consultation with one
              of our real estate agents, contact us at Amberwood Real Estate
              Inc. today.
            </p>
          </div>
        </motion.div>
      </section>
    </>
  );
};

export default BuyerOverview;