/* eslint-disable no-useless-concat */
import { Card } from "react-bootstrap";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";

/* IMAGE IMPORTS */
import AmberwoodPlaceholder from "../assets/amberwood-placeholder-img.webp";

/* ICON IMPORTS */
import BedIcon from "../assets/icons/bed-icon.png";
import BathIcon from "../assets/icons/bath-icon.png";
import CameraIcon from "@iconscout/react-unicons/icons/uil-camera";

const PropertyLoadingList = (props) => {
  return (
    <motion.div className="card listing-list" layoutId={props.streetName}>
      <Card.Body className="listing-list-body">
        <div className="property-images">
          <img
            src={AmberwoodPlaceholder}
            className="property-img"
            alt={props.streetName}
          />
        </div>
        <div className="property-details">
          <div className="top-section">
            <Skeleton className="loading-skeleton" />
            <div className="d-flex align-items-center">
              <Skeleton className="badge-loading-skeleton" />
              <div className="images-number">
                <CameraIcon size="16" color="#FFF" />
                <Skeleton className="loading-skeleton" />
              </div>
            </div>
          </div>
          <div className="middle-section">
            <div>
              <Skeleton className="street-loading-skeleton" />
              <Skeleton className="city-loading-skeleton" />
            </div>
          </div>
          <div className="bottom-section">
            <div className="d-flex align-items-center">
              <Skeleton className="size-loading-skeleton" />
              {/* <Skeleton className="lot-loading-skeleton" /> */}
            </div>
            <div className="other-details">
              <div className="details-box">
                <img src={BedIcon} className="icon" alt="Bed Icon" />
                <Skeleton className="bed-loading-skeleton" />
              </div>
              <div className="details-box">
                <img src={BathIcon} className="icon" alt="Bed Icon" />
                <Skeleton className="bed-loading-skeleton" />
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </motion.div>
  );
};

export default PropertyLoadingList;
