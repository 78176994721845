/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import Select from 'react-select';
import MortgageChart from "./chart";
import { Button } from "react-bootstrap";
import ServicesBox from "../../components/services-box";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const loanTermOptions = [
  { value: '30', label: '30 Years' },
  { value: '20', label: '20 Years' },
  { value: '15', label: '15 Years' },
  { value: '10', label: '10 Years' },
  { value: '8', label: '8 Years' }
];

const MortgageCalculator = () => {

  /* UPDATE META DATA */
  const metaTitle = "Mortgage Calculator | Amberwood Real Estate";
  const metaDescription = "Take control of your financial planning with our powerful mortgage calculator. Estimate monthly payments, analyze interest rates, and evaluate different loan scenarios to make informed decisions about your mortgage."
  const metaImage = "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png"

  document.title = metaTitle;
  document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);

  document.querySelector('meta[property="og:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[property="og:image"]').setAttribute('content', metaImage);
  document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

  document.querySelector('meta[name="twitter:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[name="twitter:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[name="twitter:image"]').setAttribute('content', metaImage);

  const [purchasePrice, setPurchasePrice] = useState('');
  const [downPayment, setDownPayment] = useState('');
  const [loanTerm, setLoanTerm] = useState({ value: '30', label: '30 Years' });
  const [interestRate, setInterestRate] = useState('');
  const [propertyTax, setPropertyTax] = useState('');
  const [insurance, setInsurance] = useState('');
  const [hoaFees, setHoaFees] = useState('');

  const [estimatedMonthlyPayment, setEstimatedMonthlyPayment] = useState('0');
  const [principalPlusInterest, setShowPrincipalPlusInterest] = useState('0');
  const [showTaxes, setShowTaxes] = useState('0');
  const [monthlyInsurance, setMonthlyInsurance] = useState('0');
  const [privateMortgageInsurance, setPrivateMortgageInsurance] = useState('0');
  const [monthlyHoa, setMonthlyHoa] = useState('0');

  const [formSubmit, setFormSubmit] = useState(false);

  const validationSchema = yup.object().shape({
    purchasePrice: yup.string().required("Purchase Price is required"),
    downPayment: yup.string().required("Down Payment is required"),
    loanTerm: yup.object().required("Loan Term is required"),
    interestRate: yup.string().required("Interest Rate is required"),
    propertyTax: yup.string().required("Property Tax is required"),
    insurance: yup.string().required("Insurance is required"),
    hoaFees: yup.string().required("Montly HOA Fees is required"),
  });

  const formik = useFormik({
    initialValues: {
      purchasePrice: "",
      downPayment: "",
      loanTerm: { value: "30", label: "30 Years" },
      interestRate: "",
      propertyTax: "",
      insurance: "",
      hoaFees: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setFormSubmit(true);
      setPurchasePrice(values?.purchasePrice);
      setDownPayment(values?.downPayment);
      setLoanTerm(values?.loanTerm);
      setInterestRate(values?.interestRate);
      setPropertyTax(values?.propertyTax);
      setInsurance(values?.insurance);
      setHoaFees(values?.hoaFees);
    },
    onReset: () => {
      setFormSubmit(false);
      setPurchasePrice('');
      setDownPayment('');
      setLoanTerm({ value: '30', label: '30 Years' });
      setInterestRate('');
      setPropertyTax('');
      setInsurance('');
      setHoaFees('');
      setEstimatedMonthlyPayment("0");
      setShowPrincipalPlusInterest("0");
      setShowTaxes("0");
      setMonthlyInsurance("0");
      setPrivateMortgageInsurance("0");
      setMonthlyHoa("0");
    },
  });

  useEffect(() => {
    if (formSubmit) {
      handleFormSubmit();
    }
  }, [formSubmit, purchasePrice, downPayment, loanTerm, interestRate, propertyTax, insurance, hoaFees])

  const calculateMortgage = () => {
    const principal = parseFloat(purchasePrice) - parseFloat(downPayment);
    const rate = parseFloat(interestRate) / 100 / 12;
    const term = parseFloat(loanTerm.value) * 12;
    const monthlyTax = parseFloat(propertyTax) / 12;
    const monthlyInsurance = parseFloat(insurance) / 12;
    const monthlyHOAFees = parseFloat(hoaFees);

    /* ESTIMATED MONTHLY PAYMENT */
    const monthlyPayment =
      (principal * rate * Math.pow(1 + rate, term)) /
      (Math.pow(1 + rate, term) - 1) +
      monthlyTax +
      monthlyInsurance +
      monthlyHOAFees;

    /* PRIVATE MORTGAGE INSURANCE */
    const pmi = principal / purchasePrice;
    const totalPMI = pmi * 100;

    /* PRINCIPAL AND INTEREST */
    const showPrincipal = monthlyPayment - monthlyTax - monthlyInsurance;
    const showInterest = (rate) * (principal);
    const showPrincipalInterest = showPrincipal + showInterest;

    return { monthlyPayment, totalPMI, showPrincipalInterest };
  };

  const handleFormSubmit = () => {
    const { monthlyPayment, totalPMI, showPrincipalInterest } = calculateMortgage();

    /* CHART VALUES */
    setEstimatedMonthlyPayment(monthlyPayment.toFixed(2));
    setShowPrincipalPlusInterest(showPrincipalInterest.toFixed(2));
    setShowTaxes((propertyTax / 12).toFixed(2));
    setMonthlyInsurance((insurance / 12).toFixed(2));
    setPrivateMortgageInsurance(totalPMI.toFixed(2));
    setMonthlyHoa(hoaFees);
  };

  const resetForm = () => {
    setFormSubmit(false)
    setPurchasePrice('');
    setDownPayment('');
    setLoanTerm({ value: 'Loan Term', label: 'Loan Term' });
    setInterestRate('');
    setPropertyTax('');
    setInsurance('');
    setHoaFees('');
    setEstimatedMonthlyPayment('0');
    setShowPrincipalPlusInterest('0');
    setShowTaxes('0');
    setMonthlyInsurance('0');
    setPrivateMortgageInsurance('0');
    setMonthlyHoa('0');
  };

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });

  return (
    <>
      <section className="mortgage-calculator">
        {/* CALCULATOR */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="calculator"
        >
          <div className="container">
            <div className="heading">
              <h1>Mortgage Calculator</h1>
              <p>
                Estimate your monthly payments with our free mortgage calculator
              </p>
            </div>

            {/* BOX */}
            <div className="calculator-box">
              <div className="row align-items-center">
                <div className="col-xl-6 col-sm-12">
                  <form onSubmit={formik.handleSubmit} id="mortgageForm">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-sm-12">
                        <div className="form-group mb-2">
                          <label className="form-label">
                            Purchase Price of Home
                          </label>
                          <div className="p-relative">
                            <input
                              className={`form-control calculator-input ${formik.errors.purchasePrice &&
                                  formik.touched.purchasePrice
                                  ? "validation-border"
                                  : ""
                                }`}
                              name="purchasePrice"
                              id="purchasePrice"
                              type="number"
                              placeholder="Purchase Price"
                              value={formik.values.purchasePrice}
                              onChange={formik.handleChange}
                            />
                            <p className="start-dollar">$</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-sm-12">
                        <div className="form-group mb-2">
                          <label className="form-label">Down Payment</label>
                          <div className="p-relative">
                            <input
                              className={`form-control calculator-input ${formik.errors.downPayment &&
                                  formik.touched.downPayment
                                  ? "validation-border"
                                  : ""
                                }`}
                              name="downPayment"
                              id="downPayment"
                              type="number"
                              placeholder="Down Payment"
                              value={formik.values.downPayment}
                              onChange={formik.handleChange}
                            />
                            <p className="start-dollar">$</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-sm-12">
                        <div className="form-group mb-2">
                          <label className="form-label">Loan Term</label>
                          <Select
                            options={loanTermOptions}
                            className={`react-select ${formik.errors.loanTerm && formik.touched.loanTerm
                                ? "validation-border"
                                : ""
                              }`}
                            classNamePrefix="custom-select"
                            aria-label="Loan Term"
                            placeholder="Loan Term"
                            value={formik.values.loanTerm}
                            onChange={(e) =>
                              formik.setFieldValue("loanTerm", e)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-sm-12">
                        <div className="form-group mb-2">
                          <label className="form-label">Interest Rate</label>
                          <div className="p-relative">
                            <input
                              className={`form-control ${formik.errors.interestRate &&
                                  formik.touched.interestRate
                                  ? "validation-border"
                                  : ""
                                }`}
                              name="interestRate"
                              id="interestRate"
                              type="number"
                              placeholder="Interest Rate"
                              value={formik.values.interestRate}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "interestRate",
                                  e.target.value
                                )
                              }
                            />
                            <p className="end-percent">%</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-sm-12">
                        <div className="form-group">
                          <label className="form-label">
                            Yearly Property Taxes
                          </label>
                          <div className="p-relative">
                            <input
                              className={`form-control calculator-input ${formik.errors.propertyTax &&
                                  formik.touched.propertyTax
                                  ? "validation-border"
                                  : ""
                                }`}
                              name="propertyTax"
                              id="propertyTax"
                              type="number"
                              placeholder="Property Tax"
                              value={formik.values.propertyTax}
                              onChange={formik.handleChange}
                            />
                            <p className="start-dollar">$</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-sm-12">
                        <div className="form-group">
                          <label className="form-label">Yearly Insurance</label>
                          <div className="p-relative">
                            <input
                              className={`form-control calculator-input ${formik.errors.insurance &&
                                  formik.touched.insurance
                                  ? "validation-border"
                                  : ""
                                }`}
                              name="insurance"
                              id="insurance"
                              placeholder="Yearly Insurance"
                              type="number"
                              value={formik.values.insurance}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "insurance",
                                  e.target.value
                                )
                              }
                            />
                            <p className="start-dollar">$</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-sm-12">
                        <div className="form-group">
                          <label className="form-label">Monthly HOA Fees</label>
                          <div className="p-relative">
                            <input
                              className={`form-control calculator-input ${formik.errors.hoaFees && formik.touched.hoaFees
                                  ? "validation-border"
                                  : ""
                                }`}
                              name="hoaFees"
                              id="hoaFees"
                              placeholder="HOA Fees"
                              type="number"
                              value={formik.values.hoaFees}
                              onChange={formik.handleChange}
                            />
                            <p className="start-dollar">$</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-sm-12">
                        <div className="d-flex align-items-center">
                          <Button type="submit" className="calc-btn">
                            CALCULATE
                          </Button>
                          <Button
                            className="reset-btn"
                            onClick={formik.handleReset}
                          >
                            RESET
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-xl-6 col-sm-12">
                  <div className="row align-items-center calc-right mt-2">
                    <div className="col-xl-7 col-lg-7 col-sm-12">
                      <div className="estimated-monthly-payment">
                        <h2>Estimated Monthly Payment</h2>
                        <h2 className="payment-monthly">
                          {currencyFormatter.format(estimatedMonthlyPayment)}
                        </h2>
                      </div>
                      <MortgageChart
                        principalAndInterest={
                          parseFloat(principalPlusInterest) === 0
                            ? 100
                            : parseFloat(principalPlusInterest)
                        }
                        taxes={parseFloat(showTaxes)}
                        insurance={parseFloat(monthlyInsurance)}
                        pmi={parseFloat(privateMortgageInsurance)}
                        hoaFees={parseFloat(monthlyHoa)}
                      />
                    </div>
                    <div className="col-xl-5 col-lg-5 col-sm-12">
                      <div className="legend">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "#008FFB" }}
                        />
                        <div className="legend-details">
                          <h2>Principal and Interest</h2>
                          <p>
                            {currencyFormatter.format(principalPlusInterest)}
                          </p>
                        </div>
                      </div>
                      <div className="legend">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "#00E396" }}
                        />
                        <div className="legend-details">
                          <h2>Taxes</h2>
                          <p>{currencyFormatter.format(showTaxes)}</p>
                        </div>
                      </div>
                      <div className="legend">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "#FEB019" }}
                        />
                        <div className="legend-details">
                          <h2>Monthly Homeowners Insurance</h2>
                          <p>{currencyFormatter.format(monthlyInsurance)}</p>
                        </div>
                      </div>
                      <div className="legend">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "#FF4560" }}
                        />
                        <div className="legend-details">
                          <h2>Private Mortgage Insurance</h2>
                          <p>
                            {currencyFormatter.format(privateMortgageInsurance)}
                          </p>
                        </div>
                      </div>
                      <div className="legend mb-0">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "#775DD0" }}
                        />
                        <div className="legend-details">
                          <h2>Monthly HOA Fees</h2>
                          <p>{currencyFormatter.format(monthlyHoa)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* HOW TO USE MORTGAGE CALCULATOR */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="how-to-use-calculator"
        >
          <div className="container">
            <div className="calculator-box">
              <h2>How to Use the Mortgage Calculator</h2>
              <div className="row terminologies">
                <div className="col-xl-6 col-lg-6 col-sm-12">
                  <div className="info-box">
                    <h3>Home Price</h3>
                    <p>
                      Enter the price of the home you want to buy. This can be
                      the price the owners are asking for or the price you think
                      you will offer.
                    </p>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-sm-12">
                  <div className="info-box">
                    <h3>Down Payment</h3>
                    <p>
                      Enter the amount of money to pay for the down payment on
                      the home. A higher down payment will lower your monthly
                      mortgage payments.
                    </p>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-sm-12">
                  <div className="info-box">
                    <h3>Term</h3>
                    <p>
                      Enter the length of your loan program. The default length
                      of the loan is set to a 30 year loan, but you can adjust
                      as needed.
                    </p>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-sm-12">
                  <div className="info-box">
                    <h3>Interest</h3>
                    <p>
                      Mortgage interest rates are influenced by the market and
                      your financial health which takes into account your credit
                      score and the down payment amount.
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-sm-12">
                  <div className="info-box">
                    <h3>Property Taxes</h3>
                    <p>
                      Your estimated annual property tax is based on the home
                      purchase price.
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-sm-12">
                  <div className="info-box">
                    <h3>Principal & Interest</h3>
                    <p>
                      This is the monthly mortgage payment which consists of
                      principle and interest
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-12 col-sm-12">
                  <div className="info-box">
                    <h3>HOA (Home Owners Association) Dues</h3>
                    <p>
                      It is a monthly payment that assists with maintaining and
                      improving properties in the association.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        <ServicesBox externalStyle="pb-0" />
      </section>
    </>
  );
};

export default MortgageCalculator;
