import ListingAgent from '../../assets/listing-agent-3.webp';
import { motion } from 'framer-motion';

const SellerOverview = () => {
  /* UPDATE META DATA */
  const metaTitle = "Seller Overview | Amberwood Real Estate";
  const metaDescription = "Maximize your selling potential with our comprehensive seller overview page. Gain valuable insights into the selling process, market trends, and effective strategies to achieve optimal results."
  const metaImage = "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png"

  document.title = metaTitle;
  document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);

  document.querySelector('meta[property="og:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[property="og:image"]').setAttribute('content', metaImage);
  document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

  document.querySelector('meta[name="twitter:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[name="twitter:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[name="twitter:image"]').setAttribute('content', metaImage);
    return (
      <>
        <section className="seller-overview">
          {/* BANNER */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            className="page-banner"
          >
            <div className="heading-overlay">
              <div className="container">
                <h1>SELLER OVERVIEW</h1>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            className="content pb-0 mb-2"
          >
            <div className="container">
              <p>
                If you’re planning on selling your home, then you might be
                wondering whether you should just do it yourself. After all, how
                hard can it be to list your house online? Odds are you think
                quite highly of your home, which means you expect to sell it
                quickly. What’s the point of paying a listing agent to do
                something you can do? The thing is, a listing agent is much more
                valuable than you might think, and not working with one can be
                detrimental to the process of selling your house.
              </p>
            </div>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            className="find-real-estate-agent"
          >
            <div className="container">
              <div className="box">
                <div className="row">
                  <div className="col-xl-6 col-sm-12">
                    <div className="overview-details">
                      <h2>What is a Listing Agent?</h2>
                      <p>
                        A listing agent is a real estate agent that represents
                        homeowners who are trying to sell their homes. When you
                        hire a listing agent, they will do the following:
                      </p>
                      <ul className="content-list">
                        <li>
                          <b>Prep your home.</b> They will recommend repairs or
                          improvements as well as offer advice on how to stage
                          your home to attract the most buyers. This process
                          will ensure that it sells quickly and at the highest
                          possible price.
                        </li>
                        <li>
                          <b>Price your home.</b> Using recent local market
                          data, they will accurately price your home to promote
                          buyer interest.
                        </li>
                        <li>
                          <b>List your home.</b> They will create a detailed
                          description coupled with inviting photos to showcase
                          your home.
                        </li>
                        <li>
                          <b>Market your home.</b> They will advertise your home
                          in order to attract motivated buyers.
                        </li>
                        <li>
                          <b>Show your home.</b> They will hold open houses as
                          well as personal showings to attract buyers.
                        </li>
                        <li>
                          <b>Negotiate deals.</b> They will negotiate with the
                          buyer’s agents on your behalf.
                        </li>
                        <li>
                          <b>Closing.</b> They will guide you through the
                          closing process.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-12">
                    <img src={ListingAgent} alt="What is a Listing Agent?" />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            className="content"
          >
            <div className="container">
              <h2>Difference Between a Listing Agent and a Buyer’s Agent</h2>
              <p>
                A listing agent is a real estate agent that represents the
                homeowner who is selling the house. They help assist and guide
                the homeowner throughout the selling process, from helping
                prepare the property for the market and pricing it properly to
                listing it, advertising it, and finally, selling it. The listing
                agent represents the seller and the buyer’s agent represents the
                buyer.
              </p>
              <h2 className="mt-3">Find a Listing Agent You Can Trust</h2>
              <p>
                Once you’ve decided to sell your home, you’ll want to find a
                listing agent to help you through what is a complicated and
                often stressful process. The thing is, there are a lot of
                listing agents out there, which means choosing the right one may
                seem like a daunting task. Not all listing agents provide the
                same quality service, after all. The following are a few tips to
                help you find a listing agent that you can trust:
              </p>
              <ul className="content-list">
                <li>
                  <b>Consider how they are paid.</b> Be wary of listing agents
                  that charge an upfront fee. They are less likely to do the
                  required legwork if they’ve already been paid. Most reputable
                  agents work on commission.
                </li>
                <li>
                  <b>Look for an experienced agent.</b> Not only do you want to
                  work with a listing agent that has experience selling houses,
                  but you want to make sure that they have experience in the
                  area where you live. It means that they understand the local
                  market and have an established network of resources at their
                  fingertips.
                </li>
                <li>
                  <b>Look for a full-time agent.</b> Part-time agents may not
                  put as much work into selling your home because they don’t
                  have as much time to dedicate to you.
                </li>
                <li>
                  <b>Ask about their marketing plan.</b> A good listing agent
                  will have a thorough marketing plan that includes advice to
                  stage the house, high-quality photographs, a detailed listing
                  on multiple websites, advertising online and offline, setting
                  up open houses, and more.
                </li>
                <li>
                  <b>Ask how they determine the pricing.</b> A good agent will
                  compare your property with similar properties that have sold
                  recently within the same area. They will also provide you with
                  a range instead of an exact number, and together you can
                  determine a good asking price to promote buyer interest.
                </li>
                <li>
                  <b>Consider how engaged they are.</b> When speaking with the
                  listing agent, pay attention to how they communicate with you.
                  Are they actually listening? Are they asking lots of
                  questions? Are they friendly? Do you get along with them? All
                  this is important since you’ll want to have a relationship
                  that’s built on trust and transparency.
                </li>
                <li>
                  <b>Ask about certifications.</b> Certain certifications and
                  memberships indicate the quality of the listing agent. The
                  more certifications they have, the better.
                </li>
                <li>
                  <b>Request referrals.</b> A reputable agent should be able to
                  refer past clients to speak on behalf of their quality.
                </li>
              </ul>
              <h2 className="mt-3">
                Find Your Listing Agent at Amberwood Real Estate Inc.
              </h2>
              <p>
                Here at Amberwood Real Estate Inc., we provide our clients with
                expert knowledge, valuable experience, a well-defined marketing
                plan, and a cohesive network to help sell your home quickly. To
                schedule a personal consultation, contact us today to speak with
                one of our listing agents.
              </p>
            </div>
          </motion.div>
        </section>
      </>
    );
};

export default SellerOverview;