import React from "react";
import { motion } from "framer-motion";

/* IMAGE IMPORTS */
import AmiKnox from "../../assets/agents/ami-knox.webp";
import MichealAvendano from "../../assets/agents/michael-avendano.webp";
import JamesSmith from "../../assets/agents/benny-chavez.webp";

const OurTeam = () => {

  /* UPDATE META DATA */
  const metaTitle = "Our Office | Amberwood Real Estate";
  const metaDescription = "Experience our exceptional office space designed to inspire productivity and foster collaboration. Discover a professional environment tailored to meet the unique needs of your business."
  const metaImage = "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png"

  document.title = metaTitle;
  document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);

  document.querySelector('meta[property="og:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[property="og:image"]').setAttribute('content', metaImage);
  document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

  document.querySelector('meta[name="twitter:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[name="twitter:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[name="twitter:image"]').setAttribute('content', metaImage);

  return (
    <>
      <section className="our-team">
        {/* BANNER */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="page-banner"
        ></motion.div>

        {/* OUR PEOPLE */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="our-people"
        >
          <div className="container">
            <h2>OUR TEAM</h2>
            <div className="row justify-content-center">
              <div className="col-xl-10 col-sm-12">
                <p>
                  We honor professionalism, hard work, individual attention, and
                  pride ourselves on conducting solid research. Our depth of
                  experience and commitment to our values combine to bring you
                  the best possible service.
                </p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-4 col-lg-6 col-sm-12">
                <div className="people-section">
                  <img src={MichealAvendano} alt="Michael Avendano" />
                  <h3>Michael Avendano</h3>
                  <p className="designation">Managing Broker</p>
                  <p className="bio">
                    Meet Michael Avendano, Our Managing Broker. He has
                    transformed our office into a productive environment. He is
                    a great example of hard work and dedication. He is
                    professional, diligent and always supportive. He is also a
                    real estate investor and owns a property management company.
                    He is happily married with 3 beautiful kids, always keeping
                    a healthy balance between family and work.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-sm-12">
                <div className="people-section">
                  <img src={AmiKnox} alt="Ami Knox" />
                  <h3>Ami Knox</h3>
                  <p className="designation">Director of Operations</p>
                  <p className="bio">
                    Meet Ami Knox, Our Director Of Operations. With 20+ years in
                    the real estate industry, she is a great asset to our firm.
                    Ami is honest, diligent, outgoing and cares deeply about
                    people. She also has a keen eye for design as she created
                    the look and feel of our industrial modern office. Stop by
                    and say hello to Ami Knox.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-sm-12">
                <div className="people-section">
                  <img src={JamesSmith} alt="Benny Chavez" />
                  <h3>Benny Chavez</h3>
                  <p className="designation">Agent Advisor</p>
                  <p className="bio">
                    Benny Chavez discovered the benefits of hard work and the
                    joy of customer satisfaction early in life, working at his
                    father’s auto repair shop at age fourteen. At age nineteen,
                    he saved enough money to buy ten acres of real estate in
                    Joshua Tree. Though this investment did not result in
                    monetary gain, it sparked an interest in real estate that
                    has blossomed into a successful career.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* OUR OFFICES */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="our-offices"
        >
          <div className="container">
            <h2>TOUR DE AMBERWOOD</h2>
            <div className="row mt-3 justify-content-center">
              <div className="col-xl-3 col-lg-6 col-sm-12">
                <div className="office-image">
                  <img
                    src="https://storage.googleapis.com/cdn-amberwoodre/office/office-01.webp"
                    alt="11060 Artesia Blvd Suite G, Cerritos, CA, USA"
                  />
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-12">
                <div className="office-image">
                  <img
                    src="https://storage.googleapis.com/cdn-amberwoodre/office/office-02.webp"
                    alt="11060 Artesia Blvd Suite G, Cerritos, CA, USA"
                  />
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-12">
                <div className="office-image">
                  <img
                    src="https://storage.googleapis.com/cdn-amberwoodre/office/office-03.webp"
                    alt="11060 Artesia Blvd Suite G, Cerritos, CA, USA"
                  />
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-12">
                <div className="office-image">
                  <img
                    src="https://storage.googleapis.com/cdn-amberwoodre/office/office-04.webp"
                    alt="11060 Artesia Blvd Suite G, Cerritos, CA, USA"
                  />
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-12">
                <div className="office-image">
                  <img
                    src="https://storage.googleapis.com/cdn-amberwoodre/office/office-05.webp"
                    alt="11060 Artesia Blvd Suite G, Cerritos, CA, USA"
                  />
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-12">
                <div className="office-image">
                  <img
                    src="https://storage.googleapis.com/cdn-amberwoodre/office/office-06.webp"
                    alt="11060 Artesia Blvd Suite G, Cerritos, CA, USA"
                  />
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-12">
                <div className="office-image">
                  <img
                    src="https://storage.googleapis.com/cdn-amberwoodre/office/office-07.webp"
                    alt="11060 Artesia Blvd Suite G, Cerritos, CA, USA"
                  />
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-12">
                <div className="office-image">
                  <img
                    src="https://storage.googleapis.com/cdn-amberwoodre/office/office-08.webp"
                    alt="11060 Artesia Blvd Suite G, Cerritos, CA, USA"
                  />
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </section>
    </>
  );
};

export default OurTeam;