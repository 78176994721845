import { motion } from "framer-motion";

const Banner = () => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.8 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="banner"
    >
      <video autoPlay muted loop playsInline>
        <source
          src="https://storage.googleapis.com/cdn-amberwoodre/cdn-video-assets/Amberwood%202-1%20(1).mp4"
          type="video/mp4"
        />
      </video>
      {/* <div className="bg-overlay" /> */}
      <div className="container">
        <div className="banner-text"></div>
      </div>
    </motion.div>
  );
};

export default Banner;
