import { motion } from "framer-motion";

const FairHousingStatement = () => {
  /* UPDATE META DATA */
  const metaTitle = "Fair Housing Statement | Amberwood Real Estate";
  const metaDescription = "Our commitment to fair housing is unwavering. Visit our 'Fair Housing Statement' page to understand our dedication to promoting equal housing opportunities for all. Learn about the laws and regulations that protect against discrimination in housing, and our pledge to uphold these principles."
  const metaImage = "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png"

  document.title = metaTitle;
  document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);

  document.querySelector('meta[property="og:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[property="og:image"]').setAttribute('content', metaImage);
  document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);

  document.querySelector('meta[name="twitter:title"]').setAttribute('content', metaTitle);
  document.querySelector('meta[name="twitter:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[name="twitter:image"]').setAttribute('content', metaImage);
  return (
    <>
      <section className="external-pages">
        {/* BANNER */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="page-banner fair-housing-statement"
        >
          <div className="heading-overlay">
            <div className="container">
              <h1>FAIR HOUSING STATEMENT</h1>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="content"
        >
          <div className="container">
            <p>
              Amberwood Real Estate Inc. fully supports the principles of the
              Fair Housing Act (Title VIII of the Civil Rights Act of 1968), as
              amended, which generally prohibits discrimination in the sale,
              rental, and financing of dwellings, and in other housing-related
              transactions, based on race, color, national origin, religion,
              sex, familial status (including children under the age of 18
              living with parents or legal custodians, pregnant women, and
              people securing custody of children under the age of 18), and
              handicap (disability). As an adjunct to the foregoing commitment,
              Amberwood Real Estate Inc., Inc. actively promotes and is
              committed to creating and fostering an environment of diversity
              throughout their respective organizations and franchise systems,
              and each views such a concept as a critical component to the
              on-going success of their business operations.
            </p>
          </div>
        </motion.div>
      </section>
    </>
  );
};

export default FairHousingStatement;
